import { IthacaFilter, Order_Direction } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LeanPage } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { ModalTypes } from '../modal'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type TemplateActionModalState =
  | ModalTypes.CLAIM_SLUG_MODAL
  | ModalTypes.CREATE_PAGE_MODAL
  | ModalTypes.PREVIEW_FLOWPAGE_TEMPLATE_MODAL
export interface FlowpageTemplateState {
  selectedPage: LeanPage | null
  queryVariables: {
    order: {
      orderDirection: Order_Direction
      orderBy: 'name' | 'createdAt' | 'modifiedAt' | 'position'
    }
    contains: string
    ithacaFilter: IthacaFilter
    removeDisplayToAll?: boolean
    includeDeleted?: boolean
  }
  templateActionModalState: {
    type: TemplateActionModalState
    props?: {
      templateId?: string
      isInheritable?: boolean
    }
  }
  /**
   * Used to update local state when a template has been deleted from the paginated list.
   */
  deletedTemplateId: string
  renamedTemplate: {
    id: string
    name: string
    description?: string | null
  }
}

const initialState: FlowpageTemplateState = {
  selectedPage: null,
  queryVariables: {
    order: {
      orderBy: 'name',
      orderDirection: Order_Direction.Asc
    },
    contains: '',
    ithacaFilter: IthacaFilter.Owned,
    removeDisplayToAll: false
  },
  templateActionModalState: { type: ModalTypes.CREATE_PAGE_MODAL },
  deletedTemplateId: '',
  renamedTemplate: {
    id: '',
    name: '',
    description: ''
  }
}

const flowpageTemplates = createSlice({
  name: 'flowpageTemplates',
  initialState,
  reducers: {
    setSelectedPage: (state, action: PayloadAction<FlowpageTemplateState['selectedPage']>) => {
      state.selectedPage = action.payload
    },
    setOrderDirection: (
      state,
      action: PayloadAction<FlowpageTemplateState['queryVariables']['order']['orderDirection']>
    ) => {
      state.queryVariables.order.orderDirection = action.payload
    },
    setOrderBy: (
      state,
      action: PayloadAction<FlowpageTemplateState['queryVariables']['order']['orderBy']>
    ) => {
      state.queryVariables.order.orderBy = action.payload
    },
    setContains: (
      state,
      action: PayloadAction<FlowpageTemplateState['queryVariables']['contains']>
    ) => {
      state.queryVariables.contains = action.payload
    },
    setIthacaFilter: (
      state,
      action: PayloadAction<FlowpageTemplateState['queryVariables']['ithacaFilter']>
    ) => {
      state.queryVariables.ithacaFilter = action.payload
      state.queryVariables.removeDisplayToAll = action.payload !== IthacaFilter.All
    },
    setDeletedTemplateId: (state, action: PayloadAction<string>) => {
      state.deletedTemplateId = action.payload
    },
    setRenamedTemplate: (
      state,
      action: PayloadAction<FlowpageTemplateState['renamedTemplate']>
    ) => {
      state.renamedTemplate = action.payload
    },
    updateTemplateActionModalState: (
      state,
      action: PayloadAction<FlowpageTemplateState['templateActionModalState']>
    ) => {
      state.templateActionModalState = action.payload
    }
  }
})

export const {
  setSelectedPage,
  setContains,
  setIthacaFilter,
  setOrderDirection,
  updateTemplateActionModalState,
  setOrderBy,
  setDeletedTemplateId,
  setRenamedTemplate
} = flowpageTemplates.actions

export default flowpageTemplates.reducer
