import { DemoModalVariants } from '@dtx-company/true-common/src/types/demoModal'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { useSelector } from 'react-redux'

export interface DemoModalState {
  demoModalVariant?: DemoModalVariants
}

const initialState: DemoModalState = {}

const demoModalSlice = createSlice({
  name: 'demoModal',
  initialState,
  reducers: {
    setDemoModalVariant: (state, action: PayloadAction<DemoModalVariants | undefined>) => {
      state.demoModalVariant = action.payload
    }
  }
})

export const { setDemoModalVariant } = demoModalSlice.actions
export default demoModalSlice.reducer

export const useDemoModalState = (): DemoModalState =>
  useSelector((state: RootState) => state.revenueReducer.demoModal)
