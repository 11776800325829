import {
  AddCircle,
  CheckCircle,
  DeleteForeverOutlined,
  FileUploadOutlined
} from '@mui/icons-material'
import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@dtx-company/design-system/src'
import { CardActionArea, CardActions, CardMedia, cardActionsClasses } from '@mui/material'
import { CodeConfigurationField } from '@dtx-company/inter-app/src/types/flowcode'
import { FC, useCallback, useRef } from 'react'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { selectError, selectFile } from '@app/code/src/machines/locg/locgSelectors'
import { useAuthCache } from '@app/authentication/AuthCache/hooks'
import { useDropzone } from 'react-dropzone'
import { useGlobalServices } from '@app/common/src/hooks/useGlobalServices'
import { useOnErrorScrollInToView } from '../hooks/useOnErrorScrollInToView'
import { useSelector } from '@xstate/react'
import useTranslation from 'next-translate/useTranslation'

export const MAX_FILE_SIZE = 1024 * 1024 * 20 // 20MB
const maxFileSizeInMB = 20

export const FileField: FC = () => {
  const { t } = useTranslation('logged-out-generator')
  const { locgService } = useGlobalServices()
  const authCacheId = useAuthCache()?.id

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*, application/pdf, audio/*, video/*',
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    onDropAccepted: files => {
      locgService.send([
        {
          type: 'SET_ERROR',
          key: CodeConfigurationField.FILE,
          value: undefined
        },
        {
          type: 'CHANGE',
          key: CodeConfigurationField.FILE,
          value: files[0]
        }
      ])
      authCacheId &&
        locgService.send({
          type: 'UPLOAD_TEMP_ASSET',
          asset: files[0],
          assetType: 'file',
          authCacheId
        })
      fireAnalyticsEvent('LoggedOutGenerator_ScanDestinationInputFile_Added', {
        fileSize: files[0].size,
        fileType: files[0].type
      })
    },
    onDropRejected: ([fileRejection]) => {
      locgService.send([
        {
          type: 'CHANGE',
          key: CodeConfigurationField.FILE,
          value: undefined
        },
        {
          type: 'SET_ERROR',
          key: CodeConfigurationField.FILE,
          value: t(`LoggedOutGenerator.files.errors.${fileRejection.errors[0].code}`, {
            maxFileSize: maxFileSizeInMB
          })
        }
      ])
      fireAnalyticsEvent('LoggedOutGenerator_ScanDestinationInputFile_Rejected', {
        fileSize: fileRejection.file.size,
        fileType: fileRejection.file.type
      })
    }
  })

  const file = useSelector(locgService, selectFile)
  const error = useSelector(locgService, selectError)(CodeConfigurationField.FILE)

  const helperText =
    error || t('LoggedOutGenerator.ScanDestination.options.file.subcomponents.FileInput.helperText')

  const ref = useRef<HTMLButtonElement>(null)
  useOnErrorScrollInToView(!!error, ref)

  const deleteFile = useCallback((): void => {
    locgService.send({
      type: 'CHANGE',
      key: CodeConfigurationField.FILE,
      value: undefined
    })
    authCacheId &&
      locgService.send({
        type: 'DELETE_TEMP_ASSET',
        assetType: 'file',
        authCacheId
      })
  }, [authCacheId, locgService])

  return (
    <>
      {/* 3px - matches input helper text */}
      <Stack gap={0.752}>
        <Card
          elevation={1}
          sx={{
            position: 'relative',
            ':focus-within, :hover': {
              [`.${cardActionsClasses.root}`]: {
                opacity: 1
              }
            }
          }}
        >
          {file && (
            <CardActions
              disableSpacing
              sx={theme => ({
                position: 'absolute',
                zIndex: 1,
                top: theme.spacing(1),
                right: theme.spacing(1),
                backgroundColor: 'surface.0',
                borderRadius: theme.spacing(8),
                boxShadow: theme.shadows[1],
                opacity: {
                  xs: 1,
                  md: 0
                }
              })}
            >
              <Tooltip title={t('LoggedOutGenerator.Tooltips.delete')}>
                <IconButton
                  aria-label={t(
                    'LoggedOutGenerator.ScanDestination.options.file.subcomponents.CardActions.removeFile'
                  )}
                  icon={DeleteForeverOutlined}
                  variant="text"
                  onClick={deleteFile}
                  data-heap-id="LoggedOutGenerator_ScanDestinationTypeFile_DeleteFileButton"
                />
              </Tooltip>
              <Tooltip title={t('LoggedOutGenerator.Tooltips.replace')}>
                <IconButton
                  aria-label={t(
                    'LoggedOutGenerator.ScanDestination.options.file.subcomponents.CardActions.replaceFile'
                  )}
                  icon={FileUploadOutlined}
                  variant="text"
                  onClick={open}
                  data-heap-id="LoggedOutGenerator_ScanDestinationTypeFile_ChangeFileButton"
                />
              </Tooltip>
            </CardActions>
          )}
          <CardActionArea
            data-heap-id="LoggedOutGenerator_ScanDestinationFileInput"
            {...getRootProps()}
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 6,
              gap: 2,
              ...(isDragActive && { backgroundColor: theme.palette.action.hover }),
              '& > p': {
                maxWidth: '100%'
              }
            })}
            ref={ref}
          >
            <CardMedia>{file ? <CheckCircle color="success" /> : <AddCircle />}</CardMedia>
            <Typography component="p" variant="labelSmall" noWrap align="center">
              {file?.name || (
                <>
                  <Box
                    component="span"
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    {t(
                      'LoggedOutGenerator.ScanDestination.options.file.subcomponents.FileInput.placeholder'
                    )}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      display: {
                        xs: 'block',
                        lg: 'none'
                      }
                    }}
                  >
                    {t(
                      'LoggedOutGenerator.ScanDestination.options.file.subcomponents.FileInput.placeholderMobile'
                    )}
                  </Box>
                </>
              )}
            </Typography>
          </CardActionArea>
          <input {...getInputProps()} />
        </Card>

        <Box
          sx={{
            mx: 2
          }}
        >
          <Typography
            component="p"
            variant="labelSmall"
            color={error ? 'error.main' : 'onSurface.secondary'}
          >
            {(error || !file) && helperText}
          </Typography>
        </Box>
      </Stack>
    </>
  )
}
