import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ProUpgradeModalState {
  sourceId: string
  isPaymentSuccessful: boolean
  hasSuccessActionFired: boolean
}

const initialState: ProUpgradeModalState = {
  sourceId: '',
  isPaymentSuccessful: false,
  hasSuccessActionFired: false
}

const proUpgradeModalSlice = createSlice({
  name: 'proUpgradeModal',
  initialState,
  reducers: {
    setSourceId: (state, action: PayloadAction<string>) => {
      state.sourceId = action.payload
    },
    setPaymentSuccessStatus: state => {
      state.isPaymentSuccessful = true
    },
    resetPaymentSuccessStatus: state => {
      state.isPaymentSuccessful = false
    },
    setSuccessActionFired: state => {
      state.hasSuccessActionFired = true
      state.isPaymentSuccessful = false
    }
  }
})

export const {
  setSourceId,
  setPaymentSuccessStatus,
  resetPaymentSuccessStatus,
  setSuccessActionFired
} = proUpgradeModalSlice.actions
export default proUpgradeModalSlice.reducer
