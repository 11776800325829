export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  JSONObject: any
}

export type Account = {
  __typename?: 'Account'
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  googleSheetsCode?: Maybe<Scalars['String']>
  hubspotApiKey?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** @deprecated Deprecated in favor of `pages` field */
  pageConnection: PageConnection
  pages: PageConnection
  /** Counts pages. If requesting pages as well use edgeCount and totalCount fields instead */
  pagesCount: Scalars['Int']
  salesforceCode?: Maybe<Scalars['String']>
  salesforceDomain?: Maybe<Scalars['String']>
  templates: TemplateConnection
}

export type AccountPageConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  entityTypeFilter?: InputMaybe<EntityType>
  entityTypesFilter?: InputMaybe<Array<EntityType>>
  first?: InputMaybe<Scalars['Float']>
  ithacaFilter?: IthacaFilter
  last?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
  where?: InputMaybe<PageSearch>
}

export type AccountPagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  entityTypeFilter?: InputMaybe<EntityType>
  entityTypesFilter?: InputMaybe<Array<EntityType>>
  first?: InputMaybe<Scalars['Float']>
  ithacaFilter?: IthacaFilter
  last?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
  where?: InputMaybe<PageSearch>
}

export type AccountPagesCountArgs = {
  ithacaFilter?: IthacaFilter
}

export type AccountTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  entityTypeFilter?: InputMaybe<EntityType>
  entityTypesFilter?: InputMaybe<Array<EntityType>>
  first?: InputMaybe<Scalars['Float']>
  ithacaFilter?: IthacaFilter
  last?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
  where?: InputMaybe<TemplateSearch>
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  id: Scalars['String']
  postalCode?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  streetAddress1?: Maybe<Scalars['String']>
  streetAddress2?: Maybe<Scalars['String']>
}

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  region?: InputMaybe<Scalars['String']>
  streetAddress1?: InputMaybe<Scalars['String']>
  streetAddress2?: InputMaybe<Scalars['String']>
}

export type AssetEntityPermission = {
  __typename?: 'AssetEntityPermission'
  entityId: Scalars['String']
  entityName?: Maybe<Scalars['String']>
  entityType: Scalars['Int']
  permissionName: Scalars['String']
}

export type AssetIdPermissionMap = {
  __typename?: 'AssetIdPermissionMap'
  assetId: Scalars['String']
  result: AssetPermissionsResult
}

export type AssetPermissionsResult = {
  __typename?: 'AssetPermissionsResult'
  error?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type AssetTag = {
  __typename?: 'AssetTag'
  id: Scalars['ID']
  pageId: Scalars['ID']
  tagName: Scalars['String']
}

export type AssetTagFieldSearch = {
  tagName?: InputMaybe<PatternMatchSearch>
}

export enum AssetTypes {
  Page = 'Page',
  Template = 'Template',
  WhiteLabel = 'WhiteLabel'
}

export type AutoDesignPage = {
  __typename?: 'AutoDesignPage'
  page: Page
  profileImageUrl?: Maybe<Scalars['String']>
  templateId: Scalars['ID']
}

export type ContactCollectionRow = {
  __typename?: 'ContactCollectionRow'
  linkCreatedAt?: Maybe<Scalars['DateTime']>
  linkDescription?: Maybe<Scalars['String']>
  linkId: Scalars['ID']
  linkLastModifiedAt?: Maybe<Scalars['DateTime']>
  linkTitle?: Maybe<Scalars['String']>
  pageId: Scalars['ID']
  pageSlug: Scalars['String']
  pageTitle?: Maybe<Scalars['String']>
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  address?: Maybe<Address>
  age?: Maybe<Scalars['String']>
  capturedAt: Scalars['String']
  custom?: Maybe<CustomCapture>
  custom2?: Maybe<CustomCapture>
  displayData?: Maybe<LinkDisplayData>
  displayName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type ContactInfoCapture = {
  address?: InputMaybe<AddressInput>
  age?: InputMaybe<Scalars['String']>
  custom?: InputMaybe<CustomCaptureInput>
  custom2?: InputMaybe<CustomCaptureInput>
  displayData?: InputMaybe<LinkDisplayDataInput>
  displayName?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  linkId: Scalars['ID']
  phone?: InputMaybe<Scalars['String']>
}

export type CreatePageInput = {
  active?: InputMaybe<Scalars['Boolean']>
  caption?: InputMaybe<Scalars['String']>
  createdFromTemplateId?: InputMaybe<Scalars['ID']>
  directoryId?: InputMaybe<Scalars['Int']>
  displayName?: InputMaybe<Scalars['String']>
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  isIndexed?: InputMaybe<Scalars['Boolean']>
  links?: InputMaybe<Array<LinkInput>>
  pageType?: InputMaybe<PageType>
  path?: InputMaybe<Scalars['String']>
  share?: InputMaybe<Scalars['Boolean']>
  slugName: Scalars['String']
  theme?: InputMaybe<ThemeInput>
}

export type CreateTemplateInput = {
  description?: InputMaybe<Scalars['String']>
  isInheritable?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  pageId: Scalars['String']
}

export type CustomCapture = {
  __typename?: 'CustomCapture'
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type CustomCaptureInput = {
  key?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type Domain = {
  __typename?: 'Domain'
  deleteReason?: Maybe<Scalars['String']>
  domain: Scalars['String']
  domainServiceSubdomainId: Scalars['ID']
  id: Scalars['ID']
  isActive: Scalars['Boolean']
}

export type DomainManagementType = {
  __typename?: 'DomainManagementType'
  deletedAt?: Maybe<Scalars['String']>
  domain: Scalars['String']
  flowpageSubdomains: Array<DomainType>
  id: Scalars['ID']
  type: Scalars['Int']
}

export type DomainType = {
  __typename?: 'DomainType'
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  domain: Scalars['String']
  id?: Maybe<Scalars['ID']>
  isCoBranded: Scalars['Boolean']
  isVerified?: Maybe<Scalars['Boolean']>
  productType?: Maybe<Scalars['Int']>
}

export type EditPageInput = {
  active?: InputMaybe<Scalars['Boolean']>
  caption?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  pageType?: InputMaybe<PageType>
  share?: InputMaybe<Scalars['Boolean']>
  templateConfig?: InputMaybe<TemplateConfigInput>
}

export type EditTemplateInput = {
  data?: InputMaybe<TemplateDataInput>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type EntityPermission = {
  __typename?: 'EntityPermission'
  entityId: Scalars['String']
  entityName?: Maybe<Scalars['String']>
  entityType: Scalars['Int']
  permissionName: Scalars['String']
}

export type EntityPermissionInput = {
  entityId: Scalars['String']
  entityType: Scalars['Int']
  permissionName: Scalars['String']
}

export enum EntityType {
  Org = 'ORG',
  Role = 'ROLE',
  Team = 'TEAM',
  User = 'USER'
}

export type FetchAssetDetails = {
  __typename?: 'FetchAssetDetails'
  assetId: Scalars['String']
  entityPermissions?: Maybe<Array<AssetEntityPermission>>
  result: AssetPermissionsResult
}

export type FlowcodeDesign = {
  __typename?: 'FlowcodeDesign'
  default: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  studioConfigId: Scalars['ID']
}

export type FlowcodeDesignInput = {
  default: Scalars['Boolean']
  name?: InputMaybe<Scalars['String']>
  studioConfigId: Scalars['ID']
}

export type GateInput = {
  age?: InputMaybe<Scalars['Int']>
  crmInput?: InputMaybe<Scalars['Boolean']>
  flowcodeId?: InputMaybe<Scalars['String']>
  nbaId?: InputMaybe<Scalars['String']>
  passwordAttempt?: InputMaybe<Scalars['String']>
}

/** Filtering for an entity */
export enum IthacaFilter {
  /** Return my entities and entities shared with me */
  All = 'ALL',
  /** Return only my owned entities */
  Owned = 'OWNED',
  /** Return only entities shared with me */
  Shared = 'SHARED'
}

export type KvInput = {
  operation?: InputMaybe<KvOperation>
  path: Array<Scalars['String']>
  value: Scalars['String']
}

/** Defines the database operation to perform with the input */
export enum KvOperation {
  Concatenate = 'CONCATENATE'
}

/** expression to use for pattern matching */
export enum Like_Expression {
  /** postgres extension. Similar to LIKE except makes the match case-insensitive according to the active locale */
  Ilike = 'ILIKE',
  /** https://www.postgresql.org/docs/current/functions-matching.html#FUNCTIONS-LIKE */
  Like = 'LIKE'
}

export type Link = TemplateInterface & {
  __typename?: 'Link'
  actionData: Scalars['JSONObject']
  active: Scalars['Boolean']
  childLinks?: Maybe<Array<Link>>
  description?: Maybe<Scalars['String']>
  displayType: Scalars['String']
  embed: Scalars['Boolean']
  id: Scalars['ID']
  linkTheme?: Maybe<LinkTheme>
  order: Scalars['Int']
  parentLinkId?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  provider: Scalars['String']
  staticAssets?: Maybe<Array<StaticAsset>>
  templateConfig?: Maybe<TemplateConfig>
  theme?: Maybe<LinkTheme>
  thumbNailImgUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type LinkAnalytics = {
  __typename?: 'LinkAnalytics'
  clicksTotal: Scalars['Int']
  linkId: Scalars['ID']
  monthlyClicks: Scalars['Int']
  order: Scalars['Int']
  provider: Scalars['String']
  thumbNailImgUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  weeklyClicks: Scalars['Int']
}

export type LinkDisplayData = {
  __typename?: 'LinkDisplayData'
  description?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
}

export type LinkDisplayDataInput = {
  description?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  title?: InputMaybe<Scalars['String']>
}

export type LinkInput = {
  actionData?: InputMaybe<Scalars['JSONObject']>
  active?: InputMaybe<Scalars['Boolean']>
  childLinks?: InputMaybe<Array<LinkInput>>
  description?: InputMaybe<Scalars['String']>
  displayType?: InputMaybe<Scalars['String']>
  embed?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  link?: InputMaybe<Scalars['String']>
  linkTheme?: InputMaybe<LinkThemeInput>
  order?: InputMaybe<Scalars['Int']>
  /** TemplateInput argument to indicate which page the action should be taken against - e.x. The page associated to the template or in the case of inheritance, one of the child pages */
  pageId?: InputMaybe<Scalars['ID']>
  parentLinkId?: InputMaybe<Scalars['ID']>
  provider: Scalars['String']
  templateConfig?: InputMaybe<TemplateConfigInput>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type LinkTheme = {
  __typename?: 'LinkTheme'
  active: Scalars['Boolean']
  borderColor?: Maybe<Scalars['String']>
  fontColor?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linkColor?: Maybe<Scalars['String']>
  linkId: Scalars['String']
  shadowColor?: Maybe<Scalars['String']>
  textSize?: Maybe<Scalars['Int']>
}

export type LinkThemeInput = {
  active?: InputMaybe<Scalars['Boolean']>
  borderColor?: InputMaybe<Scalars['String']>
  fontColor?: InputMaybe<Scalars['String']>
  linkColor?: InputMaybe<Scalars['String']>
  shadowColor?: InputMaybe<Scalars['String']>
  textSize?: InputMaybe<Scalars['Int']>
}

/** Defines an asset type whose position can be updated. */
export enum MoveAssetType {
  Template = 'TEMPLATE'
}

export type MoveLinkInput = {
  id: Scalars['ID']
  pageId?: InputMaybe<Scalars['ID']>
  position: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  addMailchimp: Scalars['String']
  assignTemplatePlans: Scalars['Boolean']
  authorizeSalesforceIntegration: Scalars['String']
  blockAndDeletePage: Scalars['Boolean']
  bulkDuplicatePages: Array<Page>
  captureContactInfo: Scalars['Boolean']
  /** @deprecated deprecated in favor of code and page decoupling */
  changePageFlowcode: ShortUrl
  createAssetTag: AssetTag
  createAssetTags: Array<AssetTag>
  /** @deprecated deprecated in favor of `createLink` mutation */
  createKlaviyoIntegration: Scalars['Boolean']
  createLink: Link
  createOrEditLinkTheme: Array<LinkTheme>
  createOrUpdateHubSpotIntegration: Scalars['Boolean']
  createPage: Page
  createPageFromTemplate: Page
  createPageSubdomain: Domain
  createSlot: Slot
  createTemplate: Template
  deleteAccount: Scalars['Boolean']
  deleteAssetPermission: Array<PageIdPermissionMap>
  deleteAssetTag: Scalars['Boolean']
  deleteAssetTags: Scalars['Boolean']
  deleteHubSpotIntegration: Scalars['Boolean']
  /** @deprecated deprecated in favor of `deleteLink` mutation */
  deleteKlaviyoIntegration: Scalars['Boolean']
  deleteLink: Scalars['Boolean']
  deleteMailchimp: Scalars['Boolean']
  deletePage: Scalars['Boolean']
  deletePageSubdomain: Scalars['Boolean']
  deleteSalesforceIntegration: Scalars['Boolean']
  deleteSlot: Scalars['Boolean']
  deleteTemplate: Scalars['Boolean']
  deleteTheme: Scalars['Boolean']
  /** @deprecated deprecated in favor of `editLink` mutation */
  editKlaviyoIntegration: Scalars['Boolean']
  editLink: Link
  editMailchimp: Scalars['String']
  editPage: Page
  editTemplate: Template
  editTheme: Theme
  enablePoapIntegration: Scalars['Boolean']
  hardDeleteTestPage: Scalars['Boolean']
  moveAsset: Scalars['String']
  moveLink: Link
  /** Checks the current verification status of a custom subdomain and returns that status */
  refreshPageSubdomainStatus: Scalars['Boolean']
  renameSlug: Page
  resetLinkThemes: Scalars['Boolean']
  /** Override any modifications made to pages created from the template */
  resetTemplate: Scalars['Boolean']
  restorePage: Scalars['Boolean']
  restorePageSubdomain: Scalars['Boolean']
  restoreTemplate: Template
  shareAssetPermission: Array<PageIdPermissionMap>
  shareAssets: Array<AssetIdPermissionMap>
  storeKV: SlotKv
  submitVote: Link
  togglePageIndexed: Scalars['Boolean']
  /** @deprecated deprecated in favor of restore page. permissions is an old concept */
  togglePermission: Page
  toggleVerified: Page
  transferPage: Page
  unshareAssets: Array<AssetIdPermissionMap>
  updateSlot: Slot
  verifyGoogleSheetsIntegration: Scalars['Boolean']
  verifySalesforceIntegration: Scalars['Boolean']
}

export type MutationAddMailchimpArgs = {
  apiKey: Scalars['String']
  pageId: Scalars['ID']
}

export type MutationAssignTemplatePlansArgs = {
  displayToAll?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  planIds: Array<Scalars['String']>
}

export type MutationAuthorizeSalesforceIntegrationArgs = {
  domain: Scalars['String']
}

export type MutationBlockAndDeletePageArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  slugName: Scalars['String']
}

export type MutationBulkDuplicatePagesArgs = {
  amount: Scalars['Int']
  email?: InputMaybe<Scalars['String']>
  pageId?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
}

export type MutationCaptureContactInfoArgs = {
  info: ContactInfoCapture
}

export type MutationChangePageFlowcodeArgs = {
  batchId: Scalars['String']
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  slugName: Scalars['String']
}

export type MutationCreateAssetTagArgs = {
  pageId: Scalars['ID']
  tagName: Scalars['String']
}

export type MutationCreateAssetTagsArgs = {
  pageId: Scalars['ID']
  tagNames: Array<Scalars['String']>
}

export type MutationCreateKlaviyoIntegrationArgs = {
  apiKey: Scalars['String']
  customListId?: InputMaybe<Scalars['String']>
  linkId: Scalars['ID']
}

export type MutationCreateLinkArgs = {
  linkInput: LinkInput
  pageId: Scalars['ID']
}

export type MutationCreateOrEditLinkThemeArgs = {
  linkIds: Array<Scalars['ID']>
  linkThemeInput: LinkThemeInput
}

export type MutationCreateOrUpdateHubSpotIntegrationArgs = {
  apiKey: Scalars['String']
}

export type MutationCreatePageArgs = {
  page: CreatePageInput
}

export type MutationCreatePageFromTemplateArgs = {
  directoryId?: InputMaybe<Scalars['Float']>
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  slugName: Scalars['String']
  templateId: Scalars['ID']
}

export type MutationCreatePageSubdomainArgs = {
  domainId: Scalars['String']
  subdomain: Scalars['String']
}

export type MutationCreateSlotArgs = {
  pageId: Scalars['ID']
  slotMetaType: Scalars['String']
  templateConfigInput?: InputMaybe<TemplateInput>
  value: Scalars['JSONObject']
}

export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput
  useDuplicate?: InputMaybe<Scalars['Boolean']>
}

export type MutationDeleteAccountArgs = {
  email: Scalars['String']
  removePages?: InputMaybe<Scalars['Boolean']>
}

export type MutationDeleteAssetPermissionArgs = {
  entityId: Scalars['String']
  pageIds: Array<Scalars['ID']>
}

export type MutationDeleteAssetTagArgs = {
  tagId: Scalars['ID']
}

export type MutationDeleteAssetTagsArgs = {
  tagIds: Array<Scalars['ID']>
}

export type MutationDeleteKlaviyoIntegrationArgs = {
  linkId: Scalars['ID']
}

export type MutationDeleteLinkArgs = {
  linkId: Scalars['ID']
  pageId?: InputMaybe<Scalars['ID']>
}

export type MutationDeleteMailchimpArgs = {
  pageId: Scalars['ID']
}

export type MutationDeletePageArgs = {
  id: Scalars['ID']
}

export type MutationDeletePageSubdomainArgs = {
  subdomainId: Scalars['String']
}

export type MutationDeleteSlotArgs = {
  pageId?: InputMaybe<Scalars['ID']>
  slotId: Scalars['ID']
}

export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteThemeArgs = {
  themeId: Scalars['ID']
}

export type MutationEditKlaviyoIntegrationArgs = {
  linkId: Scalars['ID']
  newApiKey?: InputMaybe<Scalars['String']>
  newCustomListId?: InputMaybe<Scalars['String']>
}

export type MutationEditLinkArgs = {
  linkId: Scalars['ID']
  linkInput: LinkInput
}

export type MutationEditMailchimpArgs = {
  apiKey: Scalars['String']
  pageId: Scalars['ID']
}

export type MutationEditPageArgs = {
  editPage: EditPageInput
}

export type MutationEditTemplateArgs = {
  id: Scalars['ID']
  input: EditTemplateInput
}

export type MutationEditThemeArgs = {
  themeId: Scalars['ID']
  themeInput: ThemeInput
}

export type MutationEnablePoapIntegrationArgs = {
  pageId: Scalars['ID']
}

export type MutationHardDeleteTestPageArgs = {
  pageId: Scalars['ID']
}

export type MutationMoveAssetArgs = {
  assetType: MoveAssetType
  beforeId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
}

export type MutationMoveLinkArgs = {
  input: MoveLinkInput
}

export type MutationRefreshPageSubdomainStatusArgs = {
  domainServiceSubdomainId: Scalars['String']
}

export type MutationRenameSlugArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['ID']>
  newSlug: Scalars['String']
  oldSlug: Scalars['String']
}

export type MutationResetLinkThemesArgs = {
  pageId: Scalars['ID']
}

export type MutationResetTemplateArgs = {
  id: Scalars['ID']
}

export type MutationRestorePageArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  slugName: Scalars['String']
}

export type MutationRestorePageSubdomainArgs = {
  domainServiceSubdomainId: Scalars['String']
}

export type MutationRestoreTemplateArgs = {
  id: Scalars['ID']
}

export type MutationShareAssetPermissionArgs = {
  entityId: Scalars['String']
  entityType: Scalars['Int']
  pageIds: Array<Scalars['ID']>
  sharedPermission: SharedAssetPermissions
}

export type MutationShareAssetsArgs = {
  assetIds: Array<Scalars['String']>
  assetType: AssetTypes
  entityPermissions: Array<EntityPermissionInput>
}

export type MutationStoreKvArgs = {
  slotId: Scalars['ID']
  value: Scalars['JSONObject']
}

export type MutationSubmitVoteArgs = {
  vote: SubmitVote
}

export type MutationTogglePageIndexedArgs = {
  pageId: Scalars['ID']
}

export type MutationTogglePermissionArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  slugName: Scalars['String']
}

export type MutationToggleVerifiedArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  slugName: Scalars['String']
}

export type MutationTransferPageArgs = {
  newOwnerEmail: Scalars['String']
  pageId: Scalars['ID']
}

export type MutationUnshareAssetsArgs = {
  assetIds: Array<Scalars['String']>
  assetType: AssetTypes
  entityIds: Array<Scalars['String']>
}

export type MutationUpdateSlotArgs = {
  input?: InputMaybe<KvInput>
  pageId?: InputMaybe<Scalars['ID']>
  slotId: Scalars['ID']
  templateConfigInput?: InputMaybe<TemplateInput>
}

export type MutationVerifyGoogleSheetsIntegrationArgs = {
  code: Scalars['String']
}

export type MutationVerifySalesforceIntegrationArgs = {
  code: Scalars['String']
}

export type OgImage = {
  __typename?: 'OgImage'
  height?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['String']>
}

export type OgInfo = {
  __typename?: 'OgInfo'
  ogDescription?: Maybe<Scalars['String']>
  ogImage?: Maybe<OgImage>
  ogTitle?: Maybe<Scalars['String']>
  ogType?: Maybe<Scalars['String']>
  ogUrl?: Maybe<Scalars['String']>
  requestUrl?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

/** dictates what direction the field should be ordered in */
export enum Order_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Owner = {
  __typename?: 'Owner'
  displayName?: Maybe<Scalars['String']>
  email: Scalars['String']
  ithacaId: Scalars['ID']
}

export type Page = TemplateInterface & {
  __typename?: 'Page'
  active: Scalars['Boolean']
  analytics?: Maybe<PageAnalytics>
  assetTags?: Maybe<Array<AssetTag>>
  baseTemplateId?: Maybe<Scalars['String']>
  /** @deprecated Deprecated in favor of description */
  caption?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdFromTemplate?: Maybe<Template>
  createdFromTemplateId?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deleteReason?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  directoryId?: Maybe<Scalars['Float']>
  /** @deprecated Deprecated in favor of title */
  displayName?: Maybe<Scalars['String']>
  domain?: Maybe<Domain>
  domainId?: Maybe<Scalars['String']>
  /** @deprecated Not used */
  email?: Maybe<Scalars['String']>
  /** @deprecated deprecated in favor of code and page decoupling */
  flowcodeId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isIndexed: Scalars['Boolean']
  links?: Maybe<Array<Link>>
  modifiedAt?: Maybe<Scalars['DateTime']>
  /** Accessible only if user is part of an organization */
  owner?: Maybe<Owner>
  pageType: PageType
  permission?: Maybe<EntityPermission>
  profileImage?: Maybe<Scalars['String']>
  share: Scalars['Boolean']
  /** @deprecated deprecated in favor of code and page decoupling effort */
  shortUrl: Scalars['String']
  slots?: Maybe<Array<Slot>>
  slugName: Scalars['String']
  staticAssets?: Maybe<Array<StaticAsset>>
  /** @deprecated deprecated in favor of analytics service */
  summaryStats: SummaryPageStats
  templateConfig?: Maybe<TemplateConfig>
  theme?: Maybe<Theme>
  title?: Maybe<Scalars['String']>
  verified: Scalars['Boolean']
}

export type PageSlotsArgs = {
  type?: InputMaybe<Scalars['String']>
}

export type PageAnalytics = {
  __typename?: 'PageAnalytics'
  /** @deprecated deprecated in favor of analytics service */
  linkStats: Array<LinkAnalytics>
  /** @deprecated deprecated in favor of analytics service */
  monthlyClicks: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  monthlyScans: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  monthlyVisitors: Scalars['Int']
  pageId: Scalars['ID']
  totalClicks: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  totalScans: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  unattributedLinkStats: Array<LinkAnalytics>
  /** @deprecated deprecated in favor of analytics service */
  uniqueMonthlyVisitors: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  uniqueVisitors: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  uniqueWeeklyVisitors: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  visitorsTotal: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  weeklyClicks: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  weeklyScans: Scalars['Int']
  /** @deprecated deprecated in favor of analytics service */
  weeklyVisitors: Scalars['Int']
}

export type PageConnection = {
  __typename?: 'PageConnection'
  edges: Array<Page>
  edgesCount: Scalars['Int']
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type PageFieldSearch = {
  assetTags?: InputMaybe<AssetTagFieldSearch>
  slugName?: InputMaybe<PatternMatchSearch>
  title?: InputMaybe<PatternMatchSearch>
}

export type PageIdPermissionMap = {
  __typename?: 'PageIdPermissionMap'
  pageId: Scalars['String']
  result: PermissionsResult
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PageSearch = {
  AND?: InputMaybe<Array<PageFieldSearch>>
  assetTags?: InputMaybe<AssetTagFieldSearch>
  OR?: InputMaybe<Array<PageFieldSearch>>
  slugName?: InputMaybe<PatternMatchSearch>
  title?: InputMaybe<PatternMatchSearch>
}

/** Defines the pageType field */
export enum PageType {
  AlaskaWhale = 'ALASKA_WHALE',
  AmalfiVeinCutArgento = 'AMALFI_VEIN_CUT_ARGENTO',
  Bww = 'BWW',
  CentreBellEn = 'CENTRE_BELL_EN',
  CentreBellFr = 'CENTRE_BELL_FR',
  CentreBellLogeEn = 'CENTRE_BELL_LOGE_EN',
  CentreBellLogeFr = 'CENTRE_BELL_LOGE_FR',
  /** Used when creating a page through flowtag */
  Contact = 'CONTACT',
  Default = 'DEFAULT',
  FridayBeers = 'FRIDAY_BEERS',
  JimmyJohns = 'JIMMY_JOHNS',
  KingsTheatre = 'KINGS_THEATRE',
  LibertyMutual = 'LIBERTY_MUTUAL',
  Lrc = 'LRC',
  LumioRecruiting = 'LUMIO_RECRUITING',
  LumioRep = 'LUMIO_REP',
  LyricTheatreHarryPotter = 'LYRIC_THEATRE_HARRY_POTTER',
  Marriot = 'MARRIOT',
  MiamiHeat = 'MIAMI_HEAT',
  Michigan = 'MICHIGAN',
  Mischehler = 'MISCHEHLER',
  Orpheum = 'ORPHEUM',
  Panera = 'PANERA',
  Progressive = 'PROGRESSIVE',
  Sfc = 'SFC',
  SpecialOlympics = 'SPECIAL_OLYMPICS',
  Starbucks = 'STARBUCKS',
  StateFarm = 'STATE_FARM'
}

/** Determines which type of wildcard should be used */
export enum Pattern {
  /** Used for a contains match. like "slugName" = '%flowcode%'. Will match everything that contains "flowcode" */
  Contains = 'CONTAINS',
  /** Functionally equivalent to where "slugName" = 'flowcode'. */
  Equals = 'EQUALS',
  /** Used for a suffix match. like "slugName" = 'flowcode%'. Will match everything that starts with "flowcode" */
  Prefix = 'PREFIX',
  /** Used for a prefix match. like "slugName" = '%flowcode'. Will match everything that ends with "flowcode" */
  Suffix = 'SUFFIX'
}

export type PatternMatchSearch = {
  expression?: InputMaybe<Like_Expression>
  /** Used to create exclusion filters */
  not?: InputMaybe<Scalars['Boolean']>
  pattern?: InputMaybe<Pattern>
  value: Scalars['String']
}

export type PermissionsResult = {
  __typename?: 'PermissionsResult'
  error?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type Query = {
  __typename?: 'Query'
  authorizeGoogleSheets: Scalars['String']
  autoDesignPages: Array<AutoDesignPage>
  CapturedContactInfo: Array<ContactInfo>
  checkPageOwnership: Scalars['Boolean']
  getAllSharedPagePermissions: Array<EntityPermission>
  getAssetDetails: Array<FetchAssetDetails>
  getBackgroundImageUrl?: Maybe<Scalars['String']>
  getBaseTemplate: Template
  getContactCollectionManagementRows: Array<ContactCollectionRow>
  getDomainManagementDetails: Array<DomainManagementType>
  getLink: Link
  getLinkImageUrl?: Maybe<Scalars['String']>
  /** @deprecated deprecated in favor of analytics service */
  getPageAnalytics: PageAnalytics
  getPageAssetTags: Array<AssetTag>
  getPagesForSubdomains: Array<Page>
  getProfileImageUrl?: Maybe<Scalars['String']>
  getRecommendedAssetTags: Array<Scalars['String']>
  getSlugName: Scalars['String']
  getStaticAssets?: Maybe<Array<StaticAsset>>
  getSubdomains: Array<DomainType>
  getUserAssetTags: Array<AssetTag>
  getUserPagesByAssetTag: Array<Page>
  /** @deprecated deprecated in favor of `me` query with `googleSheetsCode` field */
  hasGooglesheets: Scalars['Boolean']
  /** @deprecated deprecated in favor of `me` query with `hubspotApiKey` field */
  hasHubSpotIntegration?: Maybe<Scalars['String']>
  hasKlaviyo?: Maybe<Scalars['String']>
  hasMailchimp?: Maybe<Scalars['String']>
  hasValidGooglesheetId: Scalars['Boolean']
  me: Account
  ogInfo: OgInfo
  Page: Page
  slots: Array<Slot>
  slugReserved: Scalars['Boolean']
  slugSuggestions: Array<Scalars['String']>
  template: Template
  templates: TemplateConnection
  unauthPage: UnauthPage
}

export type QueryAutoDesignPagesArgs = {
  colors?: InputMaybe<Array<Scalars['String']>>
  count: Scalars['Int']
  logos?: InputMaybe<Array<Scalars['String']>>
  useCaseId: Scalars['String']
}

export type QueryCapturedContactInfoArgs = {
  linkId: Scalars['String']
}

export type QueryCheckPageOwnershipArgs = {
  pageId: Scalars['ID']
}

export type QueryGetAllSharedPagePermissionsArgs = {
  pageId: Scalars['ID']
}

export type QueryGetAssetDetailsArgs = {
  assetIds: Array<Scalars['String']>
  assetType: AssetTypes
  entityTypes?: InputMaybe<Array<EntityType>>
}

export type QueryGetBackgroundImageUrlArgs = {
  pageId: Scalars['ID']
}

export type QueryGetBaseTemplateArgs = {
  pageId: Scalars['ID']
}

export type QueryGetLinkArgs = {
  linkId: Scalars['ID']
}

export type QueryGetLinkImageUrlArgs = {
  linkId: Scalars['ID']
}

export type QueryGetPageAnalyticsArgs = {
  pageId: Scalars['ID']
}

export type QueryGetPageAssetTagsArgs = {
  pageId: Scalars['ID']
}

export type QueryGetPagesForSubdomainsArgs = {
  subdomainIds: Array<Scalars['ID']>
}

export type QueryGetProfileImageUrlArgs = {
  pageId: Scalars['ID']
}

export type QueryGetSlugNameArgs = {
  pageId: Scalars['ID']
}

export type QueryGetStaticAssetsArgs = {
  resourceId: Scalars['ID']
}

export type QueryGetUserPagesByAssetTagArgs = {
  tagName: Scalars['String']
}

export type QueryHasKlaviyoArgs = {
  linkId: Scalars['ID']
}

export type QueryHasMailchimpArgs = {
  pageId: Scalars['ID']
}

export type QueryHasValidGooglesheetIdArgs = {
  spreadsheetId: Scalars['String']
}

export type QueryOgInfoArgs = {
  url: Scalars['String']
}

export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
}

export type QuerySlotsArgs = {
  pageId: Scalars['ID']
  type?: InputMaybe<Scalars['String']>
}

export type QuerySlugReservedArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  slug: Scalars['String']
}

export type QuerySlugSuggestionsArgs = {
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  seed: Scalars['String']
  vertical?: InputMaybe<Scalars['String']>
}

export type QueryTemplateArgs = {
  id: Scalars['ID']
}

export type QueryTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  entityTypeFilter?: InputMaybe<EntityType>
  entityTypesFilter?: InputMaybe<Array<EntityType>>
  first?: InputMaybe<Scalars['Float']>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
  ithacaFilter?: IthacaFilter
  last?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
  removeDisplayToAll?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<TemplateSearch>
}

export type QueryUnauthPageArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>
  domainServiceSubdomain?: InputMaybe<Scalars['String']>
  gateInput?: InputMaybe<GateInput>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
  slug: Scalars['String']
}

export enum SharedAssetPermissions {
  SharedPermissionsAdmin = 'SharedPermissionsAdmin',
  SharedPermissionsCreator = 'SharedPermissionsCreator',
  SharedPermissionsEditor = 'SharedPermissionsEditor',
  SharedPermissionsViewer = 'SharedPermissionsViewer'
}

export type ShortUrl = {
  __typename?: 'ShortUrl'
  shortUrl?: Maybe<Scalars['String']>
}

export type Slot = TemplateInterface & {
  __typename?: 'Slot'
  data: Scalars['JSONObject']
  id: Scalars['ID']
  slotMeta: SlotMeta
  templateConfig?: Maybe<TemplateConfig>
}

export type SlotKv = {
  __typename?: 'SlotKV'
  data: Scalars['JSONObject']
  id: Scalars['ID']
}

export type SlotMeta = {
  __typename?: 'SlotMeta'
  data: Scalars['JSONObject']
  id: Scalars['ID']
  type: Scalars['String']
}

export type StaticAsset = {
  __typename?: 'StaticAsset'
  id: Scalars['String']
  type: StaticAssetType
  url: Scalars['String']
}

/** Describes how the asset is used on a flowpage */
export enum StaticAssetType {
  BackgroundImage = 'BACKGROUND_IMAGE',
  LinkPdf = 'LINK_PDF',
  LinkThumbnail = 'LINK_THUMBNAIL',
  ProfileImage = 'PROFILE_IMAGE'
}

export type SubmitVote = {
  linkId: Scalars['ID']
  vote: Scalars['String']
}

export type SummaryPageStats = {
  __typename?: 'SummaryPageStats'
  avgClicksPerVisit: Scalars['Int']
  clicksLast7Days: Scalars['Int']
  pageVisits?: Maybe<Scalars['Int']>
  totalClicks: Scalars['Int']
}

export type Template = {
  __typename?: 'Template'
  childPages: PageConnection
  createdAt: Scalars['DateTime']
  data?: Maybe<TemplateData>
  description?: Maybe<Scalars['String']>
  /** Used to toggle the different versions of the template card */
  displaySimpleCard: Scalars['Boolean']
  displayToAll: Scalars['Boolean']
  id: Scalars['ID']
  isAssigned: Scalars['Boolean']
  isEditor: Scalars['Boolean']
  isInheritable: Scalars['Boolean']
  isOwner: Scalars['Boolean']
  isViewer: Scalars['Boolean']
  modifiedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  owner?: Maybe<Owner>
  page: Page
  pageId: Scalars['String']
  permission?: Maybe<EntityPermission>
  position?: Maybe<Scalars['String']>
  taxonomyId?: Maybe<Scalars['String']>
}

export type TemplateChildPagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Float']>
  last?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
  where?: InputMaybe<PageSearch>
}

export type TemplateConfig = {
  __typename?: 'TemplateConfig'
  fields?: Maybe<Array<TemplateConfigField>>
  lockAll?: Maybe<Scalars['Boolean']>
  order: Scalars['Int']
  requireAll?: Maybe<Scalars['Boolean']>
}

export type TemplateConfigField = {
  __typename?: 'TemplateConfigField'
  locked: Scalars['Boolean']
  name: Scalars['String']
  required: Scalars['Boolean']
}

export type TemplateConfigFieldInput = {
  locked: Scalars['Boolean']
  name: Scalars['String']
  required: Scalars['Boolean']
}

export type TemplateConfigInput = {
  fields?: InputMaybe<Array<TemplateConfigFieldInput>>
  lockAll?: InputMaybe<Scalars['Boolean']>
  order: Scalars['Int']
  requireAll?: InputMaybe<Scalars['Boolean']>
}

export type TemplateConnection = {
  __typename?: 'TemplateConnection'
  edges: Array<TemplateEdge>
  edgesCount: Scalars['Int']
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type TemplateData = {
  __typename?: 'TemplateData'
  flowcodeDesigns: Array<FlowcodeDesign>
}

export type TemplateDataInput = {
  flowcodeDesigns: Array<FlowcodeDesignInput>
}

export type TemplateEdge = {
  __typename?: 'TemplateEdge'
  createdAt: Scalars['DateTime']
  data?: Maybe<TemplateData>
  description?: Maybe<Scalars['String']>
  /** Used to toggle the different versions of the template card */
  displaySimpleCard: Scalars['Boolean']
  displayToAll: Scalars['Boolean']
  id: Scalars['ID']
  isAssigned: Scalars['Boolean']
  isEditor: Scalars['Boolean']
  isInheritable: Scalars['Boolean']
  isOwner: Scalars['Boolean']
  isViewer: Scalars['Boolean']
  modifiedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  owner?: Maybe<Owner>
  page: Page
  pageId: Scalars['String']
  permission?: Maybe<EntityPermission>
  position?: Maybe<Scalars['String']>
  taxonomyId?: Maybe<Scalars['String']>
}

export type TemplateInput = {
  /** TemplateInput argument to indicate which page the action should be taken against - e.x. The page associated to the template or in the case of inheritance, one of the child pages */
  pageId?: InputMaybe<Scalars['ID']>
  templateConfig?: InputMaybe<TemplateConfigInput>
}

export type TemplateInterface = {
  templateConfig?: Maybe<TemplateConfig>
}

export type TemplateSearch = {
  name?: InputMaybe<PatternMatchSearch>
}

export type Theme = TemplateInterface & {
  __typename?: 'Theme'
  backgroundImgUrl?: Maybe<Scalars['String']>
  borderColor?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  fontColor?: Maybe<Scalars['String']>
  fontFamily?: Maybe<Scalars['String']>
  id: Scalars['String']
  linkColor?: Maybe<Scalars['String']>
  linkShape?: Maybe<Scalars['String']>
  linkStyle?: Maybe<Scalars['String']>
  primaryColor?: Maybe<Scalars['String']>
  profileImageMaskType?: Maybe<Scalars['String']>
  shadowColor?: Maybe<Scalars['String']>
  staticAssets?: Maybe<Array<StaticAsset>>
  style?: Maybe<Scalars['String']>
  templateConfig?: Maybe<TemplateConfig>
  textSize?: Maybe<Scalars['Int']>
  titleColor?: Maybe<Scalars['String']>
}

export type ThemeInput = {
  backgroundType?: InputMaybe<Scalars['String']>
  borderColor?: InputMaybe<Scalars['String']>
  fontColor?: InputMaybe<Scalars['String']>
  fontFamily?: InputMaybe<Scalars['String']>
  linkColor?: InputMaybe<Scalars['String']>
  linkShape?: InputMaybe<Scalars['String']>
  linkStyle?: InputMaybe<Scalars['String']>
  /** TemplateInput argument to indicate which page the action should be taken against - e.x. The page associated to the template or in the case of inheritance, one of the child pages */
  pageId?: InputMaybe<Scalars['ID']>
  primaryColor?: InputMaybe<Scalars['String']>
  profileImageMaskType?: InputMaybe<Scalars['String']>
  shadowColor?: InputMaybe<Scalars['String']>
  style?: InputMaybe<Scalars['String']>
  templateConfig?: InputMaybe<TemplateConfigInput>
  textSize?: InputMaybe<Scalars['Int']>
  titleColor?: InputMaybe<Scalars['String']>
}

export type UnauthPage = TemplateInterface & {
  __typename?: 'UnauthPage'
  active: Scalars['Boolean']
  analytics?: Maybe<PageAnalytics>
  assetTags?: Maybe<Array<AssetTag>>
  baseTemplateId?: Maybe<Scalars['String']>
  /** @deprecated Deprecated in favor of description */
  caption?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdFromTemplate?: Maybe<Template>
  createdFromTemplateId?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deleteReason?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  directoryId?: Maybe<Scalars['Float']>
  /** @deprecated Deprecated in favor of title */
  displayName?: Maybe<Scalars['String']>
  domain?: Maybe<Domain>
  domainId?: Maybe<Scalars['String']>
  /** @deprecated Not used */
  email?: Maybe<Scalars['String']>
  /** @deprecated deprecated in favor of code and page decoupling */
  flowcodeId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isIndexed: Scalars['Boolean']
  links?: Maybe<Array<Link>>
  modifiedAt?: Maybe<Scalars['DateTime']>
  /** Accessible only if user is part of an organization */
  owner?: Maybe<Owner>
  pageType: PageType
  permission?: Maybe<EntityPermission>
  profileImage?: Maybe<Scalars['String']>
  share: Scalars['Boolean']
  /** @deprecated deprecated in favor of code and page decoupling effort */
  shortUrl: Scalars['String']
  slots?: Maybe<Array<Slot>>
  slugName: Scalars['String']
  staticAssets?: Maybe<Array<StaticAsset>>
  /** @deprecated deprecated in favor of analytics service */
  summaryStats: SummaryPageStats
  templateConfig?: Maybe<TemplateConfig>
  theme?: Maybe<Theme>
  title?: Maybe<Scalars['String']>
  verified: Scalars['Boolean']
}

export type UnauthPageSlotsArgs = {
  type?: InputMaybe<Scalars['String']>
}

export type LinkInfoFragment = {
  __typename?: 'Link'
  id: string
  active: boolean
  provider: string
  type?: string | null
  title?: string | null
  description?: string | null
  thumbNailImgUrl?: string | null
  order: number
  position?: string | null
  embed: boolean
  displayType: string
  actionData: any
  templateConfig?: {
    __typename?: 'TemplateConfig'
    order: number
    lockAll?: boolean | null
    requireAll?: boolean | null
    fields?: Array<{
      __typename?: 'TemplateConfigField'
      name: string
      required: boolean
      locked: boolean
    }> | null
  } | null
  childLinks?: Array<{
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    order: number
    actionData: any
    parentLinkId?: string | null
    thumbNailImgUrl?: string | null
  }> | null
  linkTheme?: {
    __typename?: 'LinkTheme'
    linkColor?: string | null
    fontColor?: string | null
    textSize?: number | null
    borderColor?: string | null
    shadowColor?: string | null
    active: boolean
  } | null
}

export type LeanPageInfoFragment = {
  __typename?: 'Page'
  id: string
  displayName?: string | null
  email?: string | null
  shortUrl: string
  profileImage?: string | null
  caption?: string | null
  active: boolean
  pageType: PageType
  slugName: string
  verified: boolean
  share: boolean
  isIndexed: boolean
  createdAt: any
  assetTags?: Array<{ __typename?: 'AssetTag'; id: string; tagName: string; pageId: string }> | null
  permission?: {
    __typename?: 'EntityPermission'
    permissionName: string
    entityId: string
    entityName?: string | null
  } | null
  links?: Array<{ __typename?: 'Link'; type?: string | null }> | null
  owner?: {
    __typename?: 'Owner'
    displayName?: string | null
    email: string
    ithacaId: string
  } | null
  domain?: { __typename?: 'Domain'; domain: string } | null
}

export type MinimalPageInfoFragment = {
  __typename?: 'Page'
  id: string
  displayName?: string | null
  profileImage?: string | null
  caption?: string | null
  slugName: string
}

export type AllPageInfoFragment = {
  __typename?: 'Page'
  id: string
  displayName?: string | null
  email?: string | null
  shortUrl: string
  profileImage?: string | null
  caption?: string | null
  baseTemplateId?: string | null
  active: boolean
  pageType: PageType
  slugName: string
  domainId?: string | null
  share: boolean
  isIndexed: boolean
  verified: boolean
  createdAt: any
  createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
  domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
  permission?: {
    __typename?: 'EntityPermission'
    permissionName: string
    entityId: string
    entityName?: string | null
  } | null
  links?: Array<{
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    order: number
    position?: string | null
    embed: boolean
    displayType: string
    actionData: any
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
    childLinks?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      order: number
      actionData: any
      parentLinkId?: string | null
      thumbNailImgUrl?: string | null
    }> | null
    linkTheme?: {
      __typename?: 'LinkTheme'
      linkColor?: string | null
      fontColor?: string | null
      textSize?: number | null
      borderColor?: string | null
      shadowColor?: string | null
      active: boolean
    } | null
  }> | null
  assetTags?: Array<{ __typename?: 'AssetTag'; id: string; tagName: string; pageId: string }> | null
  theme?: {
    __typename?: 'Theme'
    id: string
    primaryColor?: string | null
    style?: string | null
    profileImageMaskType?: string | null
    backgroundImgUrl?: string | null
    linkStyle?: string | null
    linkShape?: string | null
    fontFamily?: string | null
    linkColor?: string | null
    fontColor?: string | null
    borderColor?: string | null
    shadowColor?: string | null
    titleColor?: string | null
    textSize?: number | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        locked: boolean
        required: boolean
      }> | null
    } | null
  } | null
  owner?: {
    __typename?: 'Owner'
    displayName?: string | null
    email: string
    ithacaId: string
  } | null
  slots?: Array<{
    __typename?: 'Slot'
    data: any
    id: string
    slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        locked: boolean
        required: boolean
      }> | null
    } | null
  }> | null
  templateConfig?: {
    __typename?: 'TemplateConfig'
    lockAll?: boolean | null
    requireAll?: boolean | null
    order: number
    fields?: Array<{
      __typename?: 'TemplateConfigField'
      name: string
      required: boolean
      locked: boolean
    }> | null
  } | null
}

export type AdminPageInfoFragment = {
  __typename?: 'Page'
  deletedAt?: any | null
  modifiedAt?: any | null
  deleteReason?: string | null
  id: string
  displayName?: string | null
  email?: string | null
  shortUrl: string
  profileImage?: string | null
  caption?: string | null
  baseTemplateId?: string | null
  active: boolean
  pageType: PageType
  slugName: string
  domainId?: string | null
  share: boolean
  isIndexed: boolean
  verified: boolean
  createdAt: any
  createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
  domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
  permission?: {
    __typename?: 'EntityPermission'
    permissionName: string
    entityId: string
    entityName?: string | null
  } | null
  links?: Array<{
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    order: number
    position?: string | null
    embed: boolean
    displayType: string
    actionData: any
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
    childLinks?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      order: number
      actionData: any
      parentLinkId?: string | null
      thumbNailImgUrl?: string | null
    }> | null
    linkTheme?: {
      __typename?: 'LinkTheme'
      linkColor?: string | null
      fontColor?: string | null
      textSize?: number | null
      borderColor?: string | null
      shadowColor?: string | null
      active: boolean
    } | null
  }> | null
  assetTags?: Array<{ __typename?: 'AssetTag'; id: string; tagName: string; pageId: string }> | null
  theme?: {
    __typename?: 'Theme'
    id: string
    primaryColor?: string | null
    style?: string | null
    profileImageMaskType?: string | null
    backgroundImgUrl?: string | null
    linkStyle?: string | null
    linkShape?: string | null
    fontFamily?: string | null
    linkColor?: string | null
    fontColor?: string | null
    borderColor?: string | null
    shadowColor?: string | null
    titleColor?: string | null
    textSize?: number | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        locked: boolean
        required: boolean
      }> | null
    } | null
  } | null
  owner?: {
    __typename?: 'Owner'
    displayName?: string | null
    email: string
    ithacaId: string
  } | null
  slots?: Array<{
    __typename?: 'Slot'
    data: any
    id: string
    slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        locked: boolean
        required: boolean
      }> | null
    } | null
  }> | null
  templateConfig?: {
    __typename?: 'TemplateConfig'
    lockAll?: boolean | null
    requireAll?: boolean | null
    order: number
    fields?: Array<{
      __typename?: 'TemplateConfigField'
      name: string
      required: boolean
      locked: boolean
    }> | null
  } | null
}

export type GetLinkQueryVariables = Exact<{
  linkId: Scalars['ID']
}>

export type GetLinkQuery = {
  __typename?: 'Query'
  getLink: {
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    order: number
    position?: string | null
    embed: boolean
    displayType: string
    actionData: any
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
    childLinks?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      order: number
      actionData: any
      parentLinkId?: string | null
      thumbNailImgUrl?: string | null
    }> | null
    linkTheme?: {
      __typename?: 'LinkTheme'
      linkColor?: string | null
      fontColor?: string | null
      textSize?: number | null
      borderColor?: string | null
      shadowColor?: string | null
      active: boolean
    } | null
  }
}

export type ChangePageFlowcodeMutationVariables = Exact<{
  batchId: Scalars['String']
  slugName: Scalars['String']
}>

export type ChangePageFlowcodeMutation = {
  __typename?: 'Mutation'
  changePageFlowcode: { __typename?: 'ShortUrl'; shortUrl?: string | null }
}

export type DeletePageMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePageMutation = { __typename?: 'Mutation'; deletePage: boolean }

export type TransferPageMutationVariables = Exact<{
  pageId: Scalars['ID']
  newOwnerEmail: Scalars['String']
}>

export type TransferPageMutation = {
  __typename?: 'Mutation'
  transferPage: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    active: boolean
    slugName: string
    share: boolean
    isIndexed: boolean
    createdAt: any
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      displayType: string
      order: number
      embed: boolean
      actionData: any
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
    } | null
  }
}

export type SubmitVoteMutationVariables = Exact<{
  vote: SubmitVote
}>

export type SubmitVoteMutation = {
  __typename?: 'Mutation'
  submitVote: {
    __typename?: 'Link'
    id: string
    actionData: any
    order: number
    active: boolean
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    displayType: string
    provider: string
    embed: boolean
  }
}

export type RestorePageMutationVariables = Exact<{
  slugName: Scalars['String']
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
}>

export type RestorePageMutation = { __typename?: 'Mutation'; restorePage: boolean }

export type UpdateSlotMutationVariables = Exact<{
  slotId: Scalars['ID']
  input?: InputMaybe<KvInput>
  templateConfigInput?: InputMaybe<TemplateInput>
  pageId?: InputMaybe<Scalars['ID']>
}>

export type UpdateSlotMutation = {
  __typename?: 'Mutation'
  updateSlot: { __typename?: 'Slot'; id: string; data: any }
}

export type DeleteSlotMutationVariables = Exact<{
  slotId: Scalars['ID']
  pageId?: InputMaybe<Scalars['ID']>
}>

export type DeleteSlotMutation = { __typename?: 'Mutation'; deleteSlot: boolean }

export type CaptureContactInfoMutationVariables = Exact<{
  info: ContactInfoCapture
}>

export type CaptureContactInfoMutation = { __typename?: 'Mutation'; captureContactInfo: boolean }

export type CreateSlotMutationVariables = Exact<{
  pageId: Scalars['ID']
  slotMetaType: Scalars['String']
  value: Scalars['JSONObject']
  templateConfigInput?: InputMaybe<TemplateInput>
}>

export type CreateSlotMutation = {
  __typename?: 'Mutation'
  createSlot: { __typename?: 'Slot'; id: string; data: any }
}

export type BlockAndDeletePageMutationVariables = Exact<{
  slugName: Scalars['String']
}>

export type BlockAndDeletePageMutation = { __typename?: 'Mutation'; blockAndDeletePage: boolean }

export type DeleteAccountMutationVariables = Exact<{
  email: Scalars['String']
  removePages?: InputMaybe<Scalars['Boolean']>
}>

export type DeleteAccountMutation = { __typename?: 'Mutation'; deleteAccount: boolean }

export type RenameSlugMutationVariables = Exact<{
  oldSlug: Scalars['String']
  newSlug: Scalars['String']
  domainServiceSubdomainId?: InputMaybe<Scalars['ID']>
}>

export type RenameSlugMutation = {
  __typename?: 'Mutation'
  renameSlug: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    active: boolean
    slugName: string
    share: boolean
    isIndexed: boolean
    createdAt: any
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      displayType: string
      order: number
      embed: boolean
      actionData: any
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
    } | null
  }
}

export type ToggleVerifiedMutationVariables = Exact<{
  slugName: Scalars['String']
}>

export type ToggleVerifiedMutation = {
  __typename?: 'Mutation'
  toggleVerified: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    active: boolean
    slugName: string
    share: boolean
    isIndexed: boolean
    verified: boolean
    createdAt: any
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      displayType: string
      order: number
      embed: boolean
      actionData: any
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    } | null
  }
}

export type BulkDuplicatePagesMutationVariables = Exact<{
  pageId: Scalars['String']
  amount: Scalars['Int']
  email: Scalars['String']
  path?: InputMaybe<Scalars['String']>
}>

export type BulkDuplicatePagesMutation = {
  __typename?: 'Mutation'
  bulkDuplicatePages: Array<{ __typename?: 'Page'; id: string; slugName: string }>
}

export type AddMailchimpMutationVariables = Exact<{
  apiKey: Scalars['String']
  pageId: Scalars['ID']
}>

export type AddMailchimpMutation = { __typename?: 'Mutation'; addMailchimp: string }

export type EditMailchimpMutationVariables = Exact<{
  apiKey: Scalars['String']
  pageId: Scalars['ID']
}>

export type EditMailchimpMutation = { __typename?: 'Mutation'; editMailchimp: string }

export type HasMailchimpQueryVariables = Exact<{
  pageId: Scalars['ID']
}>

export type HasMailchimpQuery = { __typename?: 'Query'; hasMailchimp?: string | null }

export type DeleteMailchimpMutationVariables = Exact<{
  pageId: Scalars['ID']
}>

export type DeleteMailchimpMutation = { __typename?: 'Mutation'; deleteMailchimp: boolean }

export type AuthorizeGoogleSheetsQueryVariables = Exact<{ [key: string]: never }>

export type AuthorizeGoogleSheetsQuery = { __typename?: 'Query'; authorizeGoogleSheets: string }

export type HasGooglesheetsQueryVariables = Exact<{ [key: string]: never }>

export type HasGooglesheetsQuery = { __typename?: 'Query'; hasGooglesheets: boolean }

export type HasSpreadsheetQueryVariables = Exact<{
  spreadsheetId: Scalars['String']
}>

export type HasSpreadsheetQuery = { __typename?: 'Query'; hasValidGooglesheetId: boolean }

export type CreateOrUpdateHubSpotIntegrationMutationVariables = Exact<{
  apiKey: Scalars['String']
}>

export type CreateOrUpdateHubSpotIntegrationMutation = {
  __typename?: 'Mutation'
  createOrUpdateHubSpotIntegration: boolean
}

export type DeleteHubSpotIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteHubSpotIntegrationMutation = {
  __typename?: 'Mutation'
  deleteHubSpotIntegration: boolean
}

export type CreateLinkMutationVariables = Exact<{
  pageId: Scalars['ID']
  linkInput: LinkInput
}>

export type CreateLinkMutation = {
  __typename?: 'Mutation'
  createLink: {
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    order: number
    position?: string | null
    embed: boolean
    displayType: string
    actionData: any
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
    childLinks?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      order: number
      actionData: any
      parentLinkId?: string | null
      thumbNailImgUrl?: string | null
    }> | null
    linkTheme?: {
      __typename?: 'LinkTheme'
      linkColor?: string | null
      fontColor?: string | null
      textSize?: number | null
      borderColor?: string | null
      shadowColor?: string | null
      active: boolean
    } | null
  }
}

export type EditLinkMutationVariables = Exact<{
  linkId: Scalars['ID']
  linkInput: LinkInput
}>

export type EditLinkMutation = {
  __typename?: 'Mutation'
  editLink: {
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    order: number
    position?: string | null
    embed: boolean
    displayType: string
    actionData: any
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
    childLinks?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      order: number
      actionData: any
      parentLinkId?: string | null
      thumbNailImgUrl?: string | null
    }> | null
    linkTheme?: {
      __typename?: 'LinkTheme'
      linkColor?: string | null
      fontColor?: string | null
      textSize?: number | null
      borderColor?: string | null
      shadowColor?: string | null
      active: boolean
    } | null
  }
}

export type DeleteLinkMutationVariables = Exact<{
  linkId: Scalars['ID']
  pageId?: InputMaybe<Scalars['ID']>
}>

export type DeleteLinkMutation = { __typename?: 'Mutation'; deleteLink: boolean }

export type CreateOrEditLinkThemeMutationVariables = Exact<{
  linkIds: Array<Scalars['ID']> | Scalars['ID']
  linkThemeInput: LinkThemeInput
}>

export type CreateOrEditLinkThemeMutation = {
  __typename?: 'Mutation'
  createOrEditLinkTheme: Array<{
    __typename?: 'LinkTheme'
    id: string
    active: boolean
    fontColor?: string | null
    borderColor?: string | null
    textSize?: number | null
    linkColor?: string | null
    shadowColor?: string | null
  }>
}

export type EditThemeMutationVariables = Exact<{
  themeId: Scalars['ID']
  themeInput: ThemeInput
}>

export type EditThemeMutation = {
  __typename?: 'Mutation'
  editTheme: {
    __typename?: 'Theme'
    id: string
    primaryColor?: string | null
    style?: string | null
    profileImageMaskType?: string | null
    backgroundImgUrl?: string | null
    linkColor?: string | null
    borderColor?: string | null
    shadowColor?: string | null
    textSize?: number | null
    fontColor?: string | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        locked: boolean
        required: boolean
      }> | null
    } | null
  }
}

export type CreateAssetTagsMutationVariables = Exact<{
  tagNames: Array<Scalars['String']> | Scalars['String']
  pageId: Scalars['ID']
}>

export type CreateAssetTagsMutation = {
  __typename?: 'Mutation'
  createAssetTags: Array<{ __typename?: 'AssetTag'; id: string }>
}

export type DeleteAssetTagsMutationVariables = Exact<{
  tagIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type DeleteAssetTagsMutation = { __typename?: 'Mutation'; deleteAssetTags: boolean }

export type EditPageMutationVariables = Exact<{
  editPage: EditPageInput
}>

export type EditPageMutation = {
  __typename?: 'Mutation'
  editPage: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    baseTemplateId?: string | null
    active: boolean
    pageType: PageType
    slugName: string
    domainId?: string | null
    share: boolean
    isIndexed: boolean
    verified: boolean
    createdAt: any
    createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
    domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
    permission?: {
      __typename?: 'EntityPermission'
      permissionName: string
      entityId: string
      entityName?: string | null
    } | null
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      order: number
      position?: string | null
      embed: boolean
      displayType: string
      actionData: any
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
      childLinks?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        order: number
        actionData: any
        parentLinkId?: string | null
        thumbNailImgUrl?: string | null
      }> | null
      linkTheme?: {
        __typename?: 'LinkTheme'
        linkColor?: string | null
        fontColor?: string | null
        textSize?: number | null
        borderColor?: string | null
        shadowColor?: string | null
        active: boolean
      } | null
    }> | null
    assetTags?: Array<{
      __typename?: 'AssetTag'
      id: string
      tagName: string
      pageId: string
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      linkStyle?: string | null
      linkShape?: string | null
      fontFamily?: string | null
      linkColor?: string | null
      fontColor?: string | null
      borderColor?: string | null
      shadowColor?: string | null
      titleColor?: string | null
      textSize?: number | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    } | null
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
    slots?: Array<{
      __typename?: 'Slot'
      data: any
      id: string
      slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    }> | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      lockAll?: boolean | null
      requireAll?: boolean | null
      order: number
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
  }
}

export type CreatePageMutationVariables = Exact<{
  page: CreatePageInput
}>

export type CreatePageMutation = {
  __typename?: 'Mutation'
  createPage: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    baseTemplateId?: string | null
    active: boolean
    pageType: PageType
    slugName: string
    domainId?: string | null
    share: boolean
    isIndexed: boolean
    verified: boolean
    createdAt: any
    createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
    domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
    permission?: {
      __typename?: 'EntityPermission'
      permissionName: string
      entityId: string
      entityName?: string | null
    } | null
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      order: number
      position?: string | null
      embed: boolean
      displayType: string
      actionData: any
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
      childLinks?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        order: number
        actionData: any
        parentLinkId?: string | null
        thumbNailImgUrl?: string | null
      }> | null
      linkTheme?: {
        __typename?: 'LinkTheme'
        linkColor?: string | null
        fontColor?: string | null
        textSize?: number | null
        borderColor?: string | null
        shadowColor?: string | null
        active: boolean
      } | null
    }> | null
    assetTags?: Array<{
      __typename?: 'AssetTag'
      id: string
      tagName: string
      pageId: string
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      linkStyle?: string | null
      linkShape?: string | null
      fontFamily?: string | null
      linkColor?: string | null
      fontColor?: string | null
      borderColor?: string | null
      shadowColor?: string | null
      titleColor?: string | null
      textSize?: number | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    } | null
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
    slots?: Array<{
      __typename?: 'Slot'
      data: any
      id: string
      slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    }> | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      lockAll?: boolean | null
      requireAll?: boolean | null
      order: number
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
  }
}

export type CreatePageFromTemplateMutationVariables = Exact<{
  slugName: Scalars['String']
  templateId: Scalars['ID']
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
  directoryId?: InputMaybe<Scalars['Float']>
  path?: InputMaybe<Scalars['String']>
}>

export type CreatePageFromTemplateMutation = {
  __typename?: 'Mutation'
  createPageFromTemplate: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    baseTemplateId?: string | null
    active: boolean
    pageType: PageType
    slugName: string
    domainId?: string | null
    share: boolean
    isIndexed: boolean
    verified: boolean
    createdAt: any
    createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
    domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
    permission?: {
      __typename?: 'EntityPermission'
      permissionName: string
      entityId: string
      entityName?: string | null
    } | null
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      order: number
      position?: string | null
      embed: boolean
      displayType: string
      actionData: any
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
      childLinks?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        order: number
        actionData: any
        parentLinkId?: string | null
        thumbNailImgUrl?: string | null
      }> | null
      linkTheme?: {
        __typename?: 'LinkTheme'
        linkColor?: string | null
        fontColor?: string | null
        textSize?: number | null
        borderColor?: string | null
        shadowColor?: string | null
        active: boolean
      } | null
    }> | null
    assetTags?: Array<{
      __typename?: 'AssetTag'
      id: string
      tagName: string
      pageId: string
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      linkStyle?: string | null
      linkShape?: string | null
      fontFamily?: string | null
      linkColor?: string | null
      fontColor?: string | null
      borderColor?: string | null
      shadowColor?: string | null
      titleColor?: string | null
      textSize?: number | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    } | null
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
    slots?: Array<{
      __typename?: 'Slot'
      data: any
      id: string
      slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    }> | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      lockAll?: boolean | null
      requireAll?: boolean | null
      order: number
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
  }
}

export type ToggleIsIndexMutationVariables = Exact<{
  pageId: Scalars['ID']
}>

export type ToggleIsIndexMutation = { __typename?: 'Mutation'; togglePageIndexed: boolean }

export type AuthorizeSalesforceIntegrationMutationVariables = Exact<{
  domain: Scalars['String']
}>

export type AuthorizeSalesforceIntegrationMutation = {
  __typename?: 'Mutation'
  authorizeSalesforceIntegration: string
}

export type VerifySalesforceIntegrationMutationVariables = Exact<{
  code: Scalars['String']
}>

export type VerifySalesforceIntegrationMutation = {
  __typename?: 'Mutation'
  verifySalesforceIntegration: boolean
}

export type DeleteSalesforceIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteSalesforceIntegrationMutation = {
  __typename?: 'Mutation'
  deleteSalesforceIntegration: boolean
}

export type VerifyGoogleSheetsIntegrationMutationVariables = Exact<{
  code: Scalars['String']
}>

export type VerifyGoogleSheetsIntegrationMutation = {
  __typename?: 'Mutation'
  verifyGoogleSheetsIntegration: boolean
}

export type CreatePageSubdomainMutationVariables = Exact<{
  subdomain: Scalars['String']
  domainId: Scalars['String']
}>

export type CreatePageSubdomainMutation = {
  __typename?: 'Mutation'
  createPageSubdomain: {
    __typename?: 'Domain'
    id: string
    domain: string
    isActive: boolean
    domainServiceSubdomainId: string
  }
}

export type RestorePageSubdomainMutationVariables = Exact<{
  domainServiceSubdomainId: Scalars['String']
}>

export type RestorePageSubdomainMutation = {
  __typename?: 'Mutation'
  restorePageSubdomain: boolean
}

export type DeletePageSubdomainMutationVariables = Exact<{
  subdomainId: Scalars['String']
}>

export type DeletePageSubdomainMutation = { __typename?: 'Mutation'; deletePageSubdomain: boolean }

export type RefreshPageSubdomainStatusMutationVariables = Exact<{
  domainServiceSubdomainId: Scalars['String']
}>

export type RefreshPageSubdomainStatusMutation = {
  __typename?: 'Mutation'
  refreshPageSubdomainStatus: boolean
}

export type CreatePageTemplateMutationVariables = Exact<{
  useDuplicate?: InputMaybe<Scalars['Boolean']>
  input: CreateTemplateInput
}>

export type CreatePageTemplateMutation = {
  __typename?: 'Mutation'
  createTemplate: { __typename?: 'Template'; id: string; name: string }
}

export type AssignTemplatePlansMutationVariables = Exact<{
  planIds: Array<Scalars['String']> | Scalars['String']
  id: Scalars['ID']
  displayToAll?: InputMaybe<Scalars['Boolean']>
}>

export type AssignTemplatePlansMutation = { __typename?: 'Mutation'; assignTemplatePlans: boolean }

export type ShareAssetsMutationVariables = Exact<{
  assetType: AssetTypes
  entityPermissions: Array<EntityPermissionInput> | EntityPermissionInput
  assetIds: Array<Scalars['String']> | Scalars['String']
}>

export type ShareAssetsMutation = {
  __typename?: 'Mutation'
  shareAssets: Array<{
    __typename?: 'AssetIdPermissionMap'
    result: {
      __typename?: 'AssetPermissionsResult'
      success?: boolean | null
      error?: string | null
    }
  }>
}

export type UnshareAssetsMutationVariables = Exact<{
  assetType: AssetTypes
  entityIds: Array<Scalars['String']> | Scalars['String']
  assetIds: Array<Scalars['String']> | Scalars['String']
}>

export type UnshareAssetsMutation = {
  __typename?: 'Mutation'
  unshareAssets: Array<{
    __typename?: 'AssetIdPermissionMap'
    result: {
      __typename?: 'AssetPermissionsResult'
      success?: boolean | null
      error?: string | null
    }
  }>
}

export type EditPageTemplateMutationVariables = Exact<{
  input: EditTemplateInput
  id: Scalars['ID']
}>

export type EditPageTemplateMutation = {
  __typename?: 'Mutation'
  editTemplate: { __typename?: 'Template'; id: string }
}

export type DeletePageTemplateMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePageTemplateMutation = { __typename?: 'Mutation'; deleteTemplate: boolean }

export type ResetLinkThemesMutationVariables = Exact<{
  pageId: Scalars['ID']
}>

export type ResetLinkThemesMutation = { __typename?: 'Mutation'; resetLinkThemes: boolean }

export type DeleteThemeMutationVariables = Exact<{
  themeId: Scalars['ID']
}>

export type DeleteThemeMutation = { __typename?: 'Mutation'; deleteTheme: boolean }

export type EnablePoapIntegrationMutationVariables = Exact<{
  pageId: Scalars['ID']
}>

export type EnablePoapIntegrationMutation = {
  __typename?: 'Mutation'
  enablePoapIntegration: boolean
}

export type MoveAssetMutationVariables = Exact<{
  beforeId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  assetType: MoveAssetType
}>

export type MoveAssetMutation = { __typename?: 'Mutation'; moveAsset: string }

export type MoveLinkMutationVariables = Exact<{
  input: MoveLinkInput
}>

export type MoveLinkMutation = {
  __typename?: 'Mutation'
  moveLink: {
    __typename?: 'Link'
    id: string
    active: boolean
    provider: string
    type?: string | null
    title?: string | null
    description?: string | null
    thumbNailImgUrl?: string | null
    order: number
    position?: string | null
    embed: boolean
    displayType: string
    actionData: any
    templateConfig?: {
      __typename?: 'TemplateConfig'
      order: number
      lockAll?: boolean | null
      requireAll?: boolean | null
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
    childLinks?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      order: number
      actionData: any
      parentLinkId?: string | null
      thumbNailImgUrl?: string | null
    }> | null
    linkTheme?: {
      __typename?: 'LinkTheme'
      linkColor?: string | null
      fontColor?: string | null
      textSize?: number | null
      borderColor?: string | null
      shadowColor?: string | null
      active: boolean
    } | null
  }
}

export type GetBaseTemplateQueryVariables = Exact<{
  pageId: Scalars['ID']
}>

export type GetBaseTemplateQuery = {
  __typename?: 'Query'
  getBaseTemplate: {
    __typename?: 'Template'
    id: string
    name: string
    isInheritable: boolean
    page: {
      __typename?: 'Page'
      profileImage?: string | null
      theme?: { __typename?: 'Theme'; id: string } | null
    }
  }
}

export type UnauthPageQueryVariables = Exact<{
  slug: Scalars['String']
  activeOnly?: InputMaybe<Scalars['Boolean']>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
  domainServiceSubdomain?: InputMaybe<Scalars['String']>
}>

export type UnauthPageQuery = {
  __typename?: 'Query'
  unauthPage: {
    __typename?: 'UnauthPage'
    id: string
    displayName?: string | null
    email?: string | null
    profileImage?: string | null
    caption?: string | null
    active: boolean
    pageType: PageType
    slugName: string
    verified: boolean
    share: boolean
    isIndexed: boolean
    domain?: { __typename?: 'Domain'; domain: string } | null
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      order: number
      position?: string | null
      embed: boolean
      displayType: string
      actionData: any
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
      childLinks?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        order: number
        actionData: any
        parentLinkId?: string | null
        thumbNailImgUrl?: string | null
      }> | null
      linkTheme?: {
        __typename?: 'LinkTheme'
        linkColor?: string | null
        fontColor?: string | null
        textSize?: number | null
        borderColor?: string | null
        shadowColor?: string | null
        active: boolean
      } | null
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      linkStyle?: string | null
      linkShape?: string | null
      linkColor?: string | null
      fontColor?: string | null
      borderColor?: string | null
      textSize?: number | null
      shadowColor?: string | null
      titleColor?: string | null
      fontFamily?: string | null
    } | null
    slots?: Array<{
      __typename?: 'Slot'
      data: any
      id: string
      slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
    }> | null
  }
}

export type GetPageDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetPageDetailsQuery = {
  __typename?: 'Query'
  Page: {
    __typename?: 'Page'
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    baseTemplateId?: string | null
    active: boolean
    pageType: PageType
    slugName: string
    domainId?: string | null
    share: boolean
    isIndexed: boolean
    verified: boolean
    createdAt: any
    createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
    domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
    permission?: {
      __typename?: 'EntityPermission'
      permissionName: string
      entityId: string
      entityName?: string | null
    } | null
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      order: number
      position?: string | null
      embed: boolean
      displayType: string
      actionData: any
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
      childLinks?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        order: number
        actionData: any
        parentLinkId?: string | null
        thumbNailImgUrl?: string | null
      }> | null
      linkTheme?: {
        __typename?: 'LinkTheme'
        linkColor?: string | null
        fontColor?: string | null
        textSize?: number | null
        borderColor?: string | null
        shadowColor?: string | null
        active: boolean
      } | null
    }> | null
    assetTags?: Array<{
      __typename?: 'AssetTag'
      id: string
      tagName: string
      pageId: string
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      linkStyle?: string | null
      linkShape?: string | null
      fontFamily?: string | null
      linkColor?: string | null
      fontColor?: string | null
      borderColor?: string | null
      shadowColor?: string | null
      titleColor?: string | null
      textSize?: number | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    } | null
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
    slots?: Array<{
      __typename?: 'Slot'
      data: any
      id: string
      slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    }> | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      lockAll?: boolean | null
      requireAll?: boolean | null
      order: number
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
  }
}

export type GetAdminPageDetailsQueryVariables = Exact<{
  id: Scalars['ID']
  includeDeleted?: InputMaybe<Scalars['Boolean']>
}>

export type GetAdminPageDetailsQuery = {
  __typename?: 'Query'
  Page: {
    __typename?: 'Page'
    deletedAt?: any | null
    modifiedAt?: any | null
    deleteReason?: string | null
    id: string
    displayName?: string | null
    email?: string | null
    shortUrl: string
    profileImage?: string | null
    caption?: string | null
    baseTemplateId?: string | null
    active: boolean
    pageType: PageType
    slugName: string
    domainId?: string | null
    share: boolean
    isIndexed: boolean
    verified: boolean
    createdAt: any
    createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
    domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
    permission?: {
      __typename?: 'EntityPermission'
      permissionName: string
      entityId: string
      entityName?: string | null
    } | null
    links?: Array<{
      __typename?: 'Link'
      id: string
      active: boolean
      provider: string
      type?: string | null
      title?: string | null
      description?: string | null
      thumbNailImgUrl?: string | null
      order: number
      position?: string | null
      embed: boolean
      displayType: string
      actionData: any
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
      childLinks?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        order: number
        actionData: any
        parentLinkId?: string | null
        thumbNailImgUrl?: string | null
      }> | null
      linkTheme?: {
        __typename?: 'LinkTheme'
        linkColor?: string | null
        fontColor?: string | null
        textSize?: number | null
        borderColor?: string | null
        shadowColor?: string | null
        active: boolean
      } | null
    }> | null
    assetTags?: Array<{
      __typename?: 'AssetTag'
      id: string
      tagName: string
      pageId: string
    }> | null
    theme?: {
      __typename?: 'Theme'
      id: string
      primaryColor?: string | null
      style?: string | null
      profileImageMaskType?: string | null
      backgroundImgUrl?: string | null
      linkStyle?: string | null
      linkShape?: string | null
      fontFamily?: string | null
      linkColor?: string | null
      fontColor?: string | null
      borderColor?: string | null
      shadowColor?: string | null
      titleColor?: string | null
      textSize?: number | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    } | null
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
    slots?: Array<{
      __typename?: 'Slot'
      data: any
      id: string
      slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
      templateConfig?: {
        __typename?: 'TemplateConfig'
        order: number
        lockAll?: boolean | null
        requireAll?: boolean | null
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          locked: boolean
          required: boolean
        }> | null
      } | null
    }> | null
    templateConfig?: {
      __typename?: 'TemplateConfig'
      lockAll?: boolean | null
      requireAll?: boolean | null
      order: number
      fields?: Array<{
        __typename?: 'TemplateConfigField'
        name: string
        required: boolean
        locked: boolean
      }> | null
    } | null
  }
}

export type UnauthPageImageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type UnauthPageImageQuery = {
  __typename?: 'Query'
  unauthPage: { __typename?: 'UnauthPage'; profileImage?: string | null }
}

export type SlugReservedQueryVariables = Exact<{
  slug: Scalars['String']
  domainServiceSubdomainId?: InputMaybe<Scalars['String']>
}>

export type SlugReservedQuery = { __typename?: 'Query'; slugReserved: boolean }

export type VerifiedStatusQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type VerifiedStatusQuery = {
  __typename?: 'Query'
  unauthPage: { __typename?: 'UnauthPage'; verified: boolean }
}

export type CapturedContactInfoQueryVariables = Exact<{
  linkId: Scalars['String']
}>

export type CapturedContactInfoQuery = {
  __typename?: 'Query'
  CapturedContactInfo: Array<{
    __typename?: 'ContactInfo'
    email?: string | null
    phone?: string | null
    displayName?: string | null
    gender?: string | null
    age?: string | null
    capturedAt: string
    custom?: { __typename?: 'CustomCapture'; value?: string | null } | null
    custom2?: { __typename?: 'CustomCapture'; value?: string | null } | null
    address?: {
      __typename?: 'Address'
      streetAddress1?: string | null
      streetAddress2?: string | null
      city?: string | null
      region?: string | null
      postalCode?: string | null
    } | null
    displayData?: {
      __typename?: 'LinkDisplayData'
      title?: string | null
      description?: string | null
      order?: number | null
    } | null
  }>
}

export type GetPageAnalyticsQueryVariables = Exact<{
  pageId: Scalars['ID']
}>

export type GetPageAnalyticsQuery = {
  __typename?: 'Query'
  getPageAnalytics: {
    __typename?: 'PageAnalytics'
    visitorsTotal: number
    monthlyVisitors: number
    weeklyVisitors: number
    uniqueVisitors: number
    uniqueMonthlyVisitors: number
    uniqueWeeklyVisitors: number
    totalScans: number
    monthlyScans: number
    weeklyScans: number
    totalClicks: number
    monthlyClicks: number
    weeklyClicks: number
    unattributedLinkStats: Array<{
      __typename?: 'LinkAnalytics'
      title?: string | null
      provider: string
      type?: string | null
      order: number
      thumbNailImgUrl?: string | null
      clicksTotal: number
      monthlyClicks: number
      weeklyClicks: number
    }>
    linkStats: Array<{
      __typename?: 'LinkAnalytics'
      title?: string | null
      provider: string
      type?: string | null
      order: number
      thumbNailImgUrl?: string | null
      clicksTotal: number
      monthlyClicks: number
      weeklyClicks: number
    }>
  }
}

export type SlotQueryVariables = Exact<{
  pageId: Scalars['ID']
  type?: InputMaybe<Scalars['String']>
}>

export type SlotQuery = {
  __typename?: 'Query'
  slots: Array<{
    __typename?: 'Slot'
    id: string
    data: any
    slotMeta: { __typename?: 'SlotMeta'; type: string }
  }>
}

export type SlugSuggestionsQueryVariables = Exact<{
  seed: Scalars['String']
  vertical?: InputMaybe<Scalars['String']>
}>

export type SlugSuggestionsQuery = { __typename?: 'Query'; slugSuggestions: Array<string> }

export type GetOgInfoFromUrlQueryVariables = Exact<{
  url: Scalars['String']
}>

export type GetOgInfoFromUrlQuery = {
  __typename?: 'Query'
  ogInfo: {
    __typename?: 'OgInfo'
    ogTitle?: string | null
    ogDescription?: string | null
    ogImage?: { __typename?: 'OgImage'; url?: string | null } | null
  }
}

export type PagePermissionQueryVariables = Exact<{
  pageId: Scalars['ID']
}>

export type PagePermissionQuery = { __typename?: 'Query'; checkPageOwnership: boolean }

export type AccountHasHubSpotQueryVariables = Exact<{ [key: string]: never }>

export type AccountHasHubSpotQuery = { __typename?: 'Query'; hasHubSpotIntegration?: string | null }

export type GetFlowpageAssetLabelsQueryVariables = Exact<{
  pageId: Scalars['ID']
}>

export type GetFlowpageAssetLabelsQuery = {
  __typename?: 'Query'
  getPageAssetTags: Array<{ __typename?: 'AssetTag'; id: string; tagName: string }>
}

export type GetUserAssetTagsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserAssetTagsQuery = {
  __typename?: 'Query'
  getUserAssetTags: Array<{ __typename?: 'AssetTag'; id: string; tagName: string }>
}

export type GetRecommendedAssetTagsQueryVariables = Exact<{ [key: string]: never }>

export type GetRecommendedAssetTagsQuery = {
  __typename?: 'Query'
  getRecommendedAssetTags: Array<string>
}

export type HighlightedFlowpagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Float']>
}>

export type HighlightedFlowpagesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'Account'
    pageConnection: {
      __typename?: 'PageConnection'
      edges: Array<{
        __typename?: 'Page'
        displayName?: string | null
        slugName: string
        profileImage?: string | null
        id: string
        theme?: { __typename?: 'Theme'; profileImageMaskType?: string | null } | null
        domain?: { __typename?: 'Domain'; domain: string } | null
      }>
    }
  }
}

export type PageManagementMeQueryVariables = Exact<{
  ithacaFilter?: InputMaybe<IthacaFilter>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Order_Direction>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Float']>
  last?: InputMaybe<Scalars['Float']>
  where?: InputMaybe<PageSearch>
}>

export type PageManagementMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'Account'
    pageConnection: {
      __typename?: 'PageConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        startCursor?: string | null
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
      }
      edges: Array<{
        __typename?: 'Page'
        id: string
        displayName?: string | null
        email?: string | null
        shortUrl: string
        profileImage?: string | null
        caption?: string | null
        active: boolean
        pageType: PageType
        slugName: string
        verified: boolean
        share: boolean
        isIndexed: boolean
        createdAt: any
        theme?: { __typename?: 'Theme'; profileImageMaskType?: string | null } | null
        assetTags?: Array<{
          __typename?: 'AssetTag'
          id: string
          tagName: string
          pageId: string
        }> | null
        permission?: {
          __typename?: 'EntityPermission'
          permissionName: string
          entityId: string
          entityName?: string | null
        } | null
        links?: Array<{ __typename?: 'Link'; type?: string | null }> | null
        owner?: {
          __typename?: 'Owner'
          displayName?: string | null
          email: string
          ithacaId: string
        } | null
        domain?: { __typename?: 'Domain'; domain: string } | null
      }>
    }
  }
}

export type AccountInfoMeQueryVariables = Exact<{ [key: string]: never }>

export type AccountInfoMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'Account'
    id: string
    email: string
    googleSheetsCode?: string | null
    hubspotApiKey?: string | null
    salesforceCode?: string | null
    salesforceDomain?: string | null
    createdAt: any
  }
}

export type PageCountQueryVariables = Exact<{
  ithacaFilter?: IthacaFilter
}>

export type PageCountQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'Account'
    pageConnection: { __typename?: 'PageConnection'; totalCount: number }
  }
}

export type UnauthPageIdQueryVariables = Exact<{
  slug: Scalars['String']
  domainServiceSubdomain?: InputMaybe<Scalars['String']>
}>

export type UnauthPageIdQuery = {
  __typename?: 'Query'
  unauthPage: { __typename?: 'UnauthPage'; id: string; email?: string | null }
}

export type GetSubdomainsQueryVariables = Exact<{ [key: string]: never }>

export type GetSubdomainsQuery = {
  __typename?: 'Query'
  getSubdomains: Array<{
    __typename?: 'DomainType'
    domain: string
    id?: string | null
    createdAt?: string | null
    isCoBranded: boolean
  }>
}

export type GetAssetDetailsQueryVariables = Exact<{
  assetIds: Array<Scalars['String']> | Scalars['String']
  assetType: AssetTypes
}>

export type GetAssetDetailsQuery = {
  __typename?: 'Query'
  getAssetDetails: Array<{
    __typename?: 'FetchAssetDetails'
    assetId: string
    result: {
      __typename?: 'AssetPermissionsResult'
      success?: boolean | null
      error?: string | null
    }
    entityPermissions?: Array<{
      __typename?: 'AssetEntityPermission'
      entityId: string
      entityType: number
      permissionName: string
      entityName?: string | null
    }> | null
  }>
}

export type GetPagesForSubdomainsQueryVariables = Exact<{
  subdomainIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type GetPagesForSubdomainsQuery = {
  __typename?: 'Query'
  getPagesForSubdomains: Array<{ __typename?: 'Page'; id: string; slugName: string }>
}

export type GetPageTemplatePermissionsQueryVariables = Exact<{
  assetType: AssetTypes
  assetIds: Array<Scalars['String']> | Scalars['String']
  templateId: Scalars['ID']
  entityTypes?: InputMaybe<Array<EntityType> | EntityType>
}>

export type GetPageTemplatePermissionsQuery = {
  __typename?: 'Query'
  getAssetDetails: Array<{
    __typename?: 'FetchAssetDetails'
    entityPermissions?: Array<{
      __typename?: 'AssetEntityPermission'
      entityId: string
      entityName?: string | null
      entityType: number
      permissionName: string
    }> | null
  }>
  template: {
    __typename?: 'Template'
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
  }
}

export type DomainManagementQueryVariables = Exact<{ [key: string]: never }>

export type DomainManagementQuery = {
  __typename?: 'Query'
  getDomainManagementDetails: Array<{
    __typename?: 'DomainManagementType'
    id: string
    domain: string
    type: number
    deletedAt?: string | null
    flowpageSubdomains: Array<{
      __typename?: 'DomainType'
      id?: string | null
      domain: string
      isCoBranded: boolean
      productType?: number | null
      createdAt?: string | null
      deletedAt?: string | null
      isVerified?: boolean | null
    }>
  }>
}

export type GetTemplateAssetDetailsQueryVariables = Exact<{
  assetType: AssetTypes
  assetIds: Array<Scalars['String']> | Scalars['String']
  entityTypes?: InputMaybe<Array<EntityType> | EntityType>
}>

export type GetTemplateAssetDetailsQuery = {
  __typename?: 'Query'
  getAssetDetails: Array<{
    __typename?: 'FetchAssetDetails'
    entityPermissions?: Array<{
      __typename?: 'AssetEntityPermission'
      entityId: string
      entityName?: string | null
      entityType: number
      permissionName: string
    }> | null
  }>
}

export type GetPageTemplateQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetPageTemplateQuery = {
  __typename?: 'Query'
  template: {
    __typename?: 'Template'
    id: string
    name: string
    isViewer: boolean
    description?: string | null
    isOwner: boolean
    isEditor: boolean
    modifiedAt?: any | null
    position?: string | null
    isInheritable: boolean
    owner?: {
      __typename?: 'Owner'
      displayName?: string | null
      email: string
      ithacaId: string
    } | null
    page: {
      __typename?: 'Page'
      id: string
      displayName?: string | null
      email?: string | null
      shortUrl: string
      profileImage?: string | null
      caption?: string | null
      baseTemplateId?: string | null
      active: boolean
      pageType: PageType
      slugName: string
      domainId?: string | null
      share: boolean
      isIndexed: boolean
      verified: boolean
      createdAt: any
      createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
      domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
      permission?: {
        __typename?: 'EntityPermission'
        permissionName: string
        entityId: string
        entityName?: string | null
      } | null
      links?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        thumbNailImgUrl?: string | null
        order: number
        position?: string | null
        embed: boolean
        displayType: string
        actionData: any
        templateConfig?: {
          __typename?: 'TemplateConfig'
          order: number
          lockAll?: boolean | null
          requireAll?: boolean | null
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            required: boolean
            locked: boolean
          }> | null
        } | null
        childLinks?: Array<{
          __typename?: 'Link'
          id: string
          active: boolean
          provider: string
          type?: string | null
          title?: string | null
          description?: string | null
          order: number
          actionData: any
          parentLinkId?: string | null
          thumbNailImgUrl?: string | null
        }> | null
        linkTheme?: {
          __typename?: 'LinkTheme'
          linkColor?: string | null
          fontColor?: string | null
          textSize?: number | null
          borderColor?: string | null
          shadowColor?: string | null
          active: boolean
        } | null
      }> | null
      assetTags?: Array<{
        __typename?: 'AssetTag'
        id: string
        tagName: string
        pageId: string
      }> | null
      theme?: {
        __typename?: 'Theme'
        id: string
        primaryColor?: string | null
        style?: string | null
        profileImageMaskType?: string | null
        backgroundImgUrl?: string | null
        linkStyle?: string | null
        linkShape?: string | null
        fontFamily?: string | null
        linkColor?: string | null
        fontColor?: string | null
        borderColor?: string | null
        shadowColor?: string | null
        titleColor?: string | null
        textSize?: number | null
        templateConfig?: {
          __typename?: 'TemplateConfig'
          order: number
          lockAll?: boolean | null
          requireAll?: boolean | null
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            locked: boolean
            required: boolean
          }> | null
        } | null
      } | null
      owner?: {
        __typename?: 'Owner'
        displayName?: string | null
        email: string
        ithacaId: string
      } | null
      slots?: Array<{
        __typename?: 'Slot'
        data: any
        id: string
        slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
        templateConfig?: {
          __typename?: 'TemplateConfig'
          order: number
          lockAll?: boolean | null
          requireAll?: boolean | null
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            locked: boolean
            required: boolean
          }> | null
        } | null
      }> | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        lockAll?: boolean | null
        requireAll?: boolean | null
        order: number
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
    }
    data?: {
      __typename?: 'TemplateData'
      flowcodeDesigns: Array<{
        __typename?: 'FlowcodeDesign'
        studioConfigId: string
        default: boolean
        name?: string | null
      }>
    } | null
  }
}

export type GetPageTemplatesCountQueryVariables = Exact<{
  ithacaFilter?: IthacaFilter
  removeDisplayToAll?: InputMaybe<Scalars['Boolean']>
}>

export type GetPageTemplatesCountQuery = {
  __typename?: 'Query'
  templates: { __typename?: 'TemplateConnection'; totalCount: number }
}

export type GetTemplateChildPagesQueryVariables = Exact<{
  id: Scalars['ID']
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Float']>
  last?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
}>

export type GetTemplateChildPagesQuery = {
  __typename?: 'Query'
  template: {
    __typename?: 'Template'
    name: string
    childPages: {
      __typename?: 'PageConnection'
      totalCount: number
      edges: Array<{ __typename?: 'Page'; id: string; slugName: string }>
    }
  }
}

export type GetFlowpageTemplatesQueryVariables = Exact<{
  where?: InputMaybe<TemplateSearch>
  ithacaFilter?: IthacaFilter
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: Order_Direction
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Float']>
  last?: InputMaybe<Scalars['Float']>
  entityTypeFilter?: InputMaybe<EntityType>
  entityTypesFilter?: InputMaybe<Array<EntityType> | EntityType>
  removeDisplayToAll?: InputMaybe<Scalars['Boolean']>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
}>

export type GetFlowpageTemplatesQuery = {
  __typename?: 'Query'
  templates: {
    __typename?: 'TemplateConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
    edges: Array<{
      __typename?: 'TemplateEdge'
      id: string
      name: string
      description?: string | null
      isInheritable: boolean
      position?: string | null
      createdAt: any
      modifiedAt?: any | null
      displaySimpleCard: boolean
      isAssigned: boolean
      isOwner: boolean
      isEditor: boolean
      isViewer: boolean
      displayToAll: boolean
      permission?: {
        __typename?: 'EntityPermission'
        entityId: string
        entityName?: string | null
        entityType: number
        permissionName: string
      } | null
      page: {
        __typename?: 'Page'
        id: string
        displayName?: string | null
        email?: string | null
        shortUrl: string
        profileImage?: string | null
        caption?: string | null
        baseTemplateId?: string | null
        active: boolean
        pageType: PageType
        slugName: string
        domainId?: string | null
        share: boolean
        isIndexed: boolean
        verified: boolean
        createdAt: any
        createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
        domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
        permission?: {
          __typename?: 'EntityPermission'
          permissionName: string
          entityId: string
          entityName?: string | null
        } | null
        links?: Array<{
          __typename?: 'Link'
          id: string
          active: boolean
          provider: string
          type?: string | null
          title?: string | null
          description?: string | null
          thumbNailImgUrl?: string | null
          order: number
          position?: string | null
          embed: boolean
          displayType: string
          actionData: any
          templateConfig?: {
            __typename?: 'TemplateConfig'
            order: number
            lockAll?: boolean | null
            requireAll?: boolean | null
            fields?: Array<{
              __typename?: 'TemplateConfigField'
              name: string
              required: boolean
              locked: boolean
            }> | null
          } | null
          childLinks?: Array<{
            __typename?: 'Link'
            id: string
            active: boolean
            provider: string
            type?: string | null
            title?: string | null
            description?: string | null
            order: number
            actionData: any
            parentLinkId?: string | null
            thumbNailImgUrl?: string | null
          }> | null
          linkTheme?: {
            __typename?: 'LinkTheme'
            linkColor?: string | null
            fontColor?: string | null
            textSize?: number | null
            borderColor?: string | null
            shadowColor?: string | null
            active: boolean
          } | null
        }> | null
        assetTags?: Array<{
          __typename?: 'AssetTag'
          id: string
          tagName: string
          pageId: string
        }> | null
        theme?: {
          __typename?: 'Theme'
          id: string
          primaryColor?: string | null
          style?: string | null
          profileImageMaskType?: string | null
          backgroundImgUrl?: string | null
          linkStyle?: string | null
          linkShape?: string | null
          fontFamily?: string | null
          linkColor?: string | null
          fontColor?: string | null
          borderColor?: string | null
          shadowColor?: string | null
          titleColor?: string | null
          textSize?: number | null
          templateConfig?: {
            __typename?: 'TemplateConfig'
            order: number
            lockAll?: boolean | null
            requireAll?: boolean | null
            fields?: Array<{
              __typename?: 'TemplateConfigField'
              name: string
              locked: boolean
              required: boolean
            }> | null
          } | null
        } | null
        owner?: {
          __typename?: 'Owner'
          displayName?: string | null
          email: string
          ithacaId: string
        } | null
        slots?: Array<{
          __typename?: 'Slot'
          data: any
          id: string
          slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
          templateConfig?: {
            __typename?: 'TemplateConfig'
            order: number
            lockAll?: boolean | null
            requireAll?: boolean | null
            fields?: Array<{
              __typename?: 'TemplateConfigField'
              name: string
              locked: boolean
              required: boolean
            }> | null
          } | null
        }> | null
        templateConfig?: {
          __typename?: 'TemplateConfig'
          lockAll?: boolean | null
          requireAll?: boolean | null
          order: number
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            required: boolean
            locked: boolean
          }> | null
        } | null
      }
      owner?: { __typename?: 'Owner'; ithacaId: string } | null
    }>
  }
}

export type GetFlowcodeIdFromFlowpageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type GetFlowcodeIdFromFlowpageQuery = {
  __typename?: 'Query'
  unauthPage: { __typename?: 'UnauthPage'; flowcodeId?: string | null }
}

export type GetContactCollectionManagementRowsQueryVariables = Exact<{ [key: string]: never }>

export type GetContactCollectionManagementRowsQuery = {
  __typename?: 'Query'
  getContactCollectionManagementRows: Array<{
    __typename?: 'ContactCollectionRow'
    linkId: string
    linkTitle?: string | null
    linkDescription?: string | null
    linkCreatedAt?: any | null
    linkLastModifiedAt?: any | null
    pageId: string
    pageSlug: string
    pageTitle?: string | null
  }>
}

export type AutoDesignPagesQueryVariables = Exact<{
  useCaseId: Scalars['String']
  count?: InputMaybe<Scalars['Int']>
  colors?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  logos?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type AutoDesignPagesQuery = {
  __typename?: 'Query'
  autoDesignPages: Array<{
    __typename?: 'AutoDesignPage'
    templateId: string
    profileImageUrl?: string | null
    page: {
      __typename?: 'Page'
      id: string
      displayName?: string | null
      email?: string | null
      shortUrl: string
      profileImage?: string | null
      caption?: string | null
      baseTemplateId?: string | null
      active: boolean
      pageType: PageType
      slugName: string
      domainId?: string | null
      share: boolean
      isIndexed: boolean
      verified: boolean
      createdAt: any
      createdFromTemplate?: { __typename?: 'Template'; pageId: string } | null
      domain?: { __typename?: 'Domain'; domainServiceSubdomainId: string; domain: string } | null
      permission?: {
        __typename?: 'EntityPermission'
        permissionName: string
        entityId: string
        entityName?: string | null
      } | null
      links?: Array<{
        __typename?: 'Link'
        id: string
        active: boolean
        provider: string
        type?: string | null
        title?: string | null
        description?: string | null
        thumbNailImgUrl?: string | null
        order: number
        position?: string | null
        embed: boolean
        displayType: string
        actionData: any
        templateConfig?: {
          __typename?: 'TemplateConfig'
          order: number
          lockAll?: boolean | null
          requireAll?: boolean | null
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            required: boolean
            locked: boolean
          }> | null
        } | null
        childLinks?: Array<{
          __typename?: 'Link'
          id: string
          active: boolean
          provider: string
          type?: string | null
          title?: string | null
          description?: string | null
          order: number
          actionData: any
          parentLinkId?: string | null
          thumbNailImgUrl?: string | null
        }> | null
        linkTheme?: {
          __typename?: 'LinkTheme'
          linkColor?: string | null
          fontColor?: string | null
          textSize?: number | null
          borderColor?: string | null
          shadowColor?: string | null
          active: boolean
        } | null
      }> | null
      assetTags?: Array<{
        __typename?: 'AssetTag'
        id: string
        tagName: string
        pageId: string
      }> | null
      theme?: {
        __typename?: 'Theme'
        id: string
        primaryColor?: string | null
        style?: string | null
        profileImageMaskType?: string | null
        backgroundImgUrl?: string | null
        linkStyle?: string | null
        linkShape?: string | null
        fontFamily?: string | null
        linkColor?: string | null
        fontColor?: string | null
        borderColor?: string | null
        shadowColor?: string | null
        titleColor?: string | null
        textSize?: number | null
        templateConfig?: {
          __typename?: 'TemplateConfig'
          order: number
          lockAll?: boolean | null
          requireAll?: boolean | null
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            locked: boolean
            required: boolean
          }> | null
        } | null
      } | null
      owner?: {
        __typename?: 'Owner'
        displayName?: string | null
        email: string
        ithacaId: string
      } | null
      slots?: Array<{
        __typename?: 'Slot'
        data: any
        id: string
        slotMeta: { __typename?: 'SlotMeta'; type: string; id: string }
        templateConfig?: {
          __typename?: 'TemplateConfig'
          order: number
          lockAll?: boolean | null
          requireAll?: boolean | null
          fields?: Array<{
            __typename?: 'TemplateConfigField'
            name: string
            locked: boolean
            required: boolean
          }> | null
        } | null
      }> | null
      templateConfig?: {
        __typename?: 'TemplateConfig'
        lockAll?: boolean | null
        requireAll?: boolean | null
        order: number
        fields?: Array<{
          __typename?: 'TemplateConfigField'
          name: string
          required: boolean
          locked: boolean
        }> | null
      } | null
    }
  }>
}
