import { LockModalVariants } from '@dtx-company/inter-app/src/constants/lockModal'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { useSelector } from 'react-redux'

export interface LockModalState {
  lockModalVariant?: LockModalVariants
}

const initialState: LockModalState = {}

const lockModalSlice = createSlice({
  name: 'lockModal',
  initialState,
  reducers: {
    setLockModalVariant: (state, action: PayloadAction<LockModalVariants | undefined>) => {
      state.lockModalVariant = action.payload
    }
  }
})

export const { setLockModalVariant } = lockModalSlice.actions
export default lockModalSlice.reducer

export const useLockModalState = (): LockModalState =>
  useSelector((state: RootState) => state.revenueReducer.lockModal)
