export const FLOWCODE_MARKETING_BASE_URL = 'https://www.flowcode.com/'
export const getMarketingUrls = (route: string): string => `${FLOWCODE_MARKETING_BASE_URL}${route}`

const MarketingRoutes = [
  'AFFILIATE',
  'CONTACT_SALES',
  'DEVELOPER_PORTAL',
  'ENTERPRISE',
  'FLOWTAG',
  'FREE_QR_CODE_GENERATOR',
  'INDEX',
  'PAGE',
  'PRICING',
  'PRO',
  'PRO_FLEX',
  'PRO_PLUS',
  'TERMS_OF_USE',
  'PRIVACY_POLICY',
  'FLOWCODE_2_SIGN_IN'
] as const
type MarketingRoute = (typeof MarketingRoutes)[number]
type MarketingRoutePageType = {
  [key in MarketingRoute]: string
}

export const MarketingPageRoutes: MarketingRoutePageType = {
  AFFILIATE: getMarketingUrls('affiliate'),
  CONTACT_SALES: getMarketingUrls('contact-sales'),
  DEVELOPER_PORTAL: getMarketingUrls('developer-portal'),
  ENTERPRISE: getMarketingUrls('enterprise'),
  FLOWTAG: getMarketingUrls('flowtag'),
  FREE_QR_CODE_GENERATOR: getMarketingUrls('free-qr-code-generator'),
  INDEX: getMarketingUrls(''),
  PAGE: getMarketingUrls('page'),
  PRICING: getMarketingUrls('pricing'),
  PRO: getMarketingUrls('pro'),
  PRO_FLEX: getMarketingUrls('prolus'), // update to proflex when marketing page is ready
  PRO_PLUS: getMarketingUrls('proplus'),
  TERMS_OF_USE: getMarketingUrls('terms-of-use'),
  PRIVACY_POLICY: getMarketingUrls('privacy-policy'),
  FLOWCODE_2_SIGN_IN: 'https://authn.flowcode.com/oauth/account/login'
}

export enum Routes {
  HOME = '/home',
  ACCEPT_INVITE = '/accept-invite', // to be deprecated with frontegg rollout
  ACCEPT_ORG_INVITE = '/accept-org-invite/[inviteId]', // to be deprecated with frontegg rollout
  ACCOUNT = '/account',
  ACCOUNT_CHANGE_PLAN = '/account/change-plan',
  ADMIN = '/page/admin',
  ANALYTICS = '/analytics',
  ANALYTICS_CODES = '/analytics/codes',
  ANALYTICS_CONVERSIONS = '/analytics/conversions',
  ANALYTICS_CRM = '/analytics/crm',
  ANALYTICS_EVENTS = '/analytics/events',
  ANALYTICS_INSIGHTS = '/analytics/insights',
  ANALYTICS_PAGE = '/analytics/pages/[page_id]',
  ANALYTICS_PAGES = '/analytics/pages',
  ARCHIVE_ASSETS = '/archive',
  ASSETS = '/assets',
  AUTO = '/studio/auto',
  BRAND_KIT = '/brand-kit',
  BUNDLE_DETAILS = '/bundles/[suiteId]',
  BUNDLES = '/bundles',
  BUY_PAGE = '/buy',
  BUY_PLAN_PAGE = '/buy/[planType]',
  CANVA_AUTHENTICATE = '/api/canva/authenticate',
  CANVA_REDIRECT = '/api/canva/redirect',
  CODE = '/codes/[batchId]',
  CODE_NOT_FOUND = '/404/code-not-found',
  CODEE = '/autobuilder',
  CODEE_CODE_CREATION = '/codee-code-creation',
  CODEE_LLM = '/codee-llm',
  CODES = '/codes',
  CONFIRM_PASSWORD_RESET = '/confirmresetpassword',
  CONTACT_SHARE = '/claim/contact',
  CONTACT2_FLOWTAG = '/claim/contact2',
  COUPON = '/[slug]/coupon',
  CREATE_CODE_SUCCESS = '/create-code-success',
  CREATE_FLOWCODE = '/createflowcode',
  CREATE_FLOWCODE_AMEX = '/createflowcode/amex',
  CRM_FORMS = '/forms',
  CRM_FORMS_EDITOR = '/forms/[slug]/editor',
  CRM_FORMS_SUBMISSIONS = '/forms/[slug]/submissions',
  CRM_SUBMISSIONS_PAGE = '/page/[slug]/submissions',
  DASHBOARD = '/dashboard',
  DISCOVER_FLOWCODE_ORG_ACTIVITY = '/discover-flowcode-org-activity',
  VALIDATE_PRIVACY_REQUEST = '/validate-privacy-request',
  DOMAINS = '/domains',
  EDIT_CODE = '/codes/[batchId]/edit',
  EDIT_STUDIO = '/studio/edit',
  FLOWPAGE = '/page/[slug]',
  FLOWPAGE_CHECKOUT_SUCCESS = '/[slug]/checkoutSuccess',
  FOLDERS = '/folders',
  FORGOT_PASSWORD = '/forgotpassword',
  FREE_QR_CODE_GENERATOR = '/free-qr-code-generator',
  FRONTEGG_SOCIAL_REDIRECT = '/frontegg-social-redirect',
  FRONTEGG_SSO_REDIRECT = '/frontegg-sso-redirect',
  GET_STARTED_FP = 'page/getstarted',
  GSHEETS_ONBOARDING_SUCCESS = '/page/google-onboarding-success',
  IMPERSONATION = '/impersonation',
  INDEX = '/',
  INDEX_TABBED = '/landing/tabbedvariant',
  INTERNAL_TOOLS = '/internal-tools',
  INVITE = '/invite',
  MAINTENANCE = '/maintenance',
  MEMBER = '/org/member',
  MY_ORG_INVITATIONS = '/my-org/invitations',
  MY_ORG_MEMBERS = '/my-org/members',
  MY_ORG_TEAM = '/my-org/teams/[teamId]',
  MY_ORG_TEAMS = '/my-org/teams',
  NOT_FOUND = '/404',
  NOTICE = '/privacy-policy#notice-at-collection', // note: this redirects to webflow
  ONBOARDING_QUESTIONS = '/onboarding-questions',
  OPT_OUT = '/optout',
  ORG = '/org',
  ORG_MEMBER = '/org/member',
  PAGE = '/page',
  PAGE_ANALYTICS = '/page/analytics',
  PAGE_EDITOR = '/pages/[id]',
  PAGE_TEMPLATE_EDITOR = '/pages/templates/[id]',
  PAGES = '/pages',
  PAGEV2_RENDERER = '/c/pages/[pageId]',
  PAUSED_ACCOUNT = '/paused-account',
  PAY = '/pay',
  PIXEL_MANAGEMENT = '/analytics/pixel',
  PRESET = '/claim/preset',
  PRESET_FP = '/claim/presetfp',
  PRIVACY_REQUEST = '/privacyrequest',
  PRIVACY_REQUEST_SUBMITTED = '/privacyrequest/complete',
  PRO_FLEX = '/use-cases/proflex',
  REPORT = '/reports/[dashboard_slug]',
  REPORTS = '/reports',
  RESERVE_SLUG = '/page/reserveslug',
  RESET_PASSWORD = '/forgotpassword',
  RESOURCES = '/resources',
  SALESFORCE_ONBOARDING_SUCCESS = '/page/salesforce-onboarding-success',
  SEARCH_RESULTS = '/search-results',
  SELECT_PLAN = '/select-plan',
  SIGN_IN = '/signin',
  SIGN_UP = '/signup',
  SSO_LOGIN = '/sso/login',
  STO_GENERIC = '/claim/generic',
  STRIPE_ONBOARDING_SUCCESS = '/stripe-signup-success',
  STUDIO = '/studio',
  STUDIO_COMPONENTS = '/studio/components',
  STUDIO_LIBRARY = '/studio/library',
  STUDIO_TEMPLATES = '/studio/templates',
  TAGGING = '/tagging',
  TEMPLATES_DISCOVER = '/templates/discover',
  TEMPLATES_DISCOVER_CODES = '/templates/discover/codes',
  TEMPLATES_DISCOVER_PAGES = '/templates/discover/pages',
  TEMPLATES_PLACEMENT = '/templates/placement',
  TEMPLATES_YOUR_CODES = '/templates/your/codes',
  TEMPLATES_YOUR_CREATE = '/templates/your/create',
  TEMPLATES_YOUR_PAGES = '/templates/your/pages',
  TEMPLATES_YOUR_TEMPLATES = '/templates/your',
  TRASH = '/trash',
  UNVERIFIED_ASSET = '/unverified-asset',
  UPGRADE = '/upgrade',
  UPGRADE_DOWNGRADE = '/upgrade-downgrade',
  VERIFY_EMAIL = '/verify-email',
  VIDEO_TOOL = '/video-qr-code-tool' // That URL does redirect to the video tool from https://github.com/dtx-company/tv-animation
}

export const protectedRoutes = [
  '/page/[slug]',
  '/page/profile',
  '/pages/[id]',
  '/signin',
  '/signup',
  '/sso'
]

export const allowedPausedAccountRoutes = {
  billingAdmin: [Routes.PAUSED_ACCOUNT, Routes.ACCOUNT, Routes.SELECT_PLAN],
  user: [Routes.PAUSED_ACCOUNT],
  pilotPlanBillingAdmin: [Routes.PAUSED_ACCOUNT, Routes.SELECT_PLAN, Routes.BUY_PLAN_PAGE],
  pilotPlanUser: [Routes.PAUSED_ACCOUNT, Routes.SELECT_PLAN]
}
