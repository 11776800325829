import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import billingTabDialogReducer, {
  BillingTabDialogState
} from '@dtx-company/inter-app/src/redux/slices/billingTabDialogSlice'
import contactUsLockModalSlice, { ContactUsLockModalSlice } from '../slices/contactUsLockModalSlice'
import demoModalReducer, {
  DemoModalState
} from '@dtx-company/inter-app/src/redux/slices/demoModalSlice'
import lockModalReducer, {
  LockModalState
} from '@dtx-company/inter-app/src/redux/slices/lockModalSlice'
import lockedFeatureModalReducer, {
  LockedFeatureModalState
} from '../slices/lockedFeatureModalSlice'
import lockedFeaturesReducer, { LockedFeaturesState } from '../slices/lockedFeaturesSlice'
import proUpgradeModalReducer, {
  ProUpgradeModalState
} from '@dtx-company/inter-app/src/redux/slices/proUpgradeModalSlice'

export const revenueCombinedReducer: Reducer<
  CombinedState<{
    proUpgradeModal: ProUpgradeModalState
    lockModal: LockModalState
    demoModal: DemoModalState
    contactUsLockModal: ContactUsLockModalSlice
    billingTabDialog: BillingTabDialogState
    lockedFeatures: LockedFeaturesState
    lockedFeatureModal: LockedFeatureModalState
  }>,
  AnyAction
> = combineReducers({
  proUpgradeModal: proUpgradeModalReducer,
  lockModal: lockModalReducer,
  demoModal: demoModalReducer,
  contactUsLockModal: contactUsLockModalSlice,
  billingTabDialog: billingTabDialogReducer,
  lockedFeatures: lockedFeaturesReducer,
  lockedFeatureModal: lockedFeatureModalReducer
})
