import { ITHACA_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'

const ACCESS_TOKEN_PREFIX =
  process.env.APP_ENV === 'production' ? '' : `${process.env.APP_ENV ?? 'staging'}_`
export const IS_FRONTEGG_USER_COOKIE_NAME = `${ACCESS_TOKEN_PREFIX}is_frontegg_user`
export const ACCESS_TOKEN_COOKIE_NAME = `${ACCESS_TOKEN_PREFIX}frontegg_access_token`
export const IMPERSONATION_ACCESS_TOKEN_COOKIE_NAME = `${ACCESS_TOKEN_PREFIX}impersonation_frontegg_access_token`

export const getFronteggCustomDomain = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `auth-stg.flowcode.com`
    case 'preprod':
      return `authn.preprod.flowcode.com` // was auth-preprod.flowcode.com for internal admin but I think that is wrong
    case 'production':
    default:
      return `authn.flowcode.com`
  }
}

export const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export const FRONTEGG_AUTH_HEADER = {
  'frontegg-vendor-host': getFronteggCustomDomain()
}

export const FRONTEGG_AUTH_JSON_HEADERS = {
  ...JSON_HEADERS,
  ...FRONTEGG_AUTH_HEADER
}

export const FRONTEGG_LOGOUT_ROUTE = `https://${getFronteggCustomDomain()}/identity/resources/auth/v1/logout`
export const FRONTEGG_LOGIN_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/auth/v1/user`
export const FRONTEGG_PRELOGIN_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/auth/v2/user/sso/prelogin`
export const FRONTEGG_EXISTING_ACCESS_TOKEN_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/sessions/v1/me`
export const FRONTEGG_REFRESH_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/auth/v1/user/token/refresh`
export const FRONTEGG_RESET_ACTIVATION_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/v1/activate/reset`
export const FRONTEGG_SIGNUP_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/v1/signUp`
export const FRONTEGG_SOCIALS_LOGIN_CONFIG_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/sso/v2`
export const FRONTEGG_SOCIALS_POST_LOGIN_ENDPOINT = {
  google: `https://${getFronteggCustomDomain()}/frontegg/identity/resources/auth/v1/user/sso/google/postlogin`,
  facebook: `https://${getFronteggCustomDomain()}/frontegg/identity/resources/auth/v1/user/sso/facebook/postlogin`
}
export const FRONTEGG_ACTIVATE_ACCOUNT_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/v1/activate`
export const FRONTEGG_TRIGGER_RESET_PASSWORD_ENDPOINT = `
https://${getFronteggCustomDomain()}/identity/resources/users/v1/passwords/reset`
export const FRONTEGG_RESET_PASSWORD_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/v1/passwords/reset/verify`
export const FRONTEGG_UPDATE_USER_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/v2/me`
export const VENDOR_TOKEN_URL =
  ITHACA_ENDPOINT +
  `/v2/frontegg-vendor-token?` +
  new URLSearchParams({ clientId: process.env.FRONTEGG_CLIENT_ID || '' }).toString()
export const getFronteggUserVendorEndpoint = (userId: string): string =>
  `https://api.us.frontegg.com/identity/resources/vendor-only/users/v1/${userId}`
export const SOCIAL_AUTH_URL = {
  google: 'https://accounts.google.com/o/oauth2/v2/auth',
  facebook: 'https://www.facebook.com/v10.0/dialog/oauth'
}
export const SOCIAL_PROVIDERS = Object.keys(FRONTEGG_SOCIALS_POST_LOGIN_ENDPOINT)

export const getGenerateResetPasswordEndpoint = (userId: string): string =>
  `https://api.us.frontegg.com/identity/resources/users/v1/${userId}/links/generate-password-reset-token`
export const getFronteggUpdateEmailEndpoint = (userId: string): string =>
  `https://api.us.frontegg.com/identity/resources/users/v1/${userId}/email`

export const FRONTEGG_CHANGE_PASSWORD_ENDPOINT = `https://${getFronteggCustomDomain()}/identity/resources/users/v1/passwords/change`

export const DEFAULT_FRONTEGG_ID = '00000000-0000-0000-0000-000000000000'
