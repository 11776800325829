const key = 'askOnboardingQuestions'
const keyUseCase = 'onboardingQuestionUseCase'
const keyIndustry = 'onboardingQuestionIndustry'
const keyRole = 'onboardingQuestionRole'

export function getAskOnboardingQuestions(): boolean | undefined {
  if (typeof window === 'undefined') {
    return true
  }
  const storage = window.localStorage.getItem(key)
  switch (storage) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return undefined
  }
}

export function setAskOnboardingQuestions(value: boolean): void {
  if (typeof window === 'undefined') {
    return
  }
  window.localStorage.setItem(key, value.toString())
}

export function setOnboardingQuestionUseCase(value: string): void {
  if (typeof window === 'undefined') {
    return
  }
  window.localStorage.setItem(keyUseCase, value)
}

export function getOnboardingQuestionUseCase(): string | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }
  return window.localStorage.getItem(keyUseCase) || undefined
}

export function setOnboardingQuestionIndustry(value: string): void {
  if (typeof window === 'undefined') {
    return
  }
  window.localStorage.setItem(keyIndustry, value)
}

export function getOnboardingQuestionIndustry(): string | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }
  return window.localStorage.getItem(keyIndustry) || undefined
}

export function setOnboardingQuestionRole(value: string): void {
  if (typeof window === 'undefined') {
    return
  }
  window.localStorage.setItem(keyRole, value)
}
