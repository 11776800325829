import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type ContactUsLockModalVariants = 'codeLimit' | 'pageLimit' | 'contactUs'

export interface ContactUsLockModalSlice {
  contactUsLockModalVariant?: ContactUsLockModalVariants
}

const initialState: ContactUsLockModalSlice = {}

const contactUsLockModalSlice = createSlice({
  name: 'contactUsLockModal',
  initialState,
  reducers: {
    setContactUsLockModalVariant: (
      state,
      action: PayloadAction<ContactUsLockModalVariants | undefined>
    ) => {
      state.contactUsLockModalVariant = action.payload
    }
  }
})

export const { setContactUsLockModalVariant } = contactUsLockModalSlice.actions
export default contactUsLockModalSlice.reducer
