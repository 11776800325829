import { FLOWCODE_ROOT } from '../constants/root'
import { getAnalyticsCollectorRootUrl } from '../utils/urls/services'

export const FLOWPAGE_API_PROXY_ROUTE = '/api/proxy/flowpage-api'
export const ATLAS_DEV_PROXY_ROUTE = '/api/proxy/atlas-dev-only'
export const CONTACT_SERVICE_PROXY_ROUTE = '/api/proxy/contact-service'
export const FLOWCODE_API_PROXY_ROUTE = '/api/proxy/flowcode-api'
export const LEATHERWOOD_API_PROXY_ROUTE = '/api/proxy/leatherwood'
export const ANALYTICS_COLLECTOR_PROXY_ROUTE = '/api/proxy/analytics-collector'
export const SUBSCRIPTION_SERVICE_PROXY_ROUTE = '/api/proxy/subscription-service'
export const COMMERCE_WORKER_PROXY_ROUTE = '/api/proxy/commerce-worker'
export const VCARD_API_PROXY_ROUTE = '/api/proxy/vcard-service'
export const NOTIFICATIONS_API_PROXY_ROUTE = '/api/proxy/notifications'
export const AUTHENTICATION_V2_PROXY_ROUTE = '/api/proxy/authentication-v2'
export const AUTHENTICATION_V4_PROXY_ROUTE = '/api/proxy/authentication-v4'
export const LLM_PROXY_ROUTE = '/api/proxy/llm'
export const ITHACA_PROXY_ENDPOINT = '/api/proxy/ithaca'
export const ANALYTICS_COLLECTOR_ENDPOINT = getAnalyticsCollectorRootUrl() // Passing the Analytics Collector through the proxy results in loss of the client IP in the headers; ticket SC-71536 will address this

export const FLOWPAGE_API_ENDPOINT = `${FLOWCODE_ROOT}${FLOWPAGE_API_PROXY_ROUTE}`
export const CONTACT_SERVICE_ENDPOINT = `${FLOWCODE_ROOT}${CONTACT_SERVICE_PROXY_ROUTE}`
export const FLOWCODE_API_ENDPOINT = `${FLOWCODE_ROOT}${FLOWCODE_API_PROXY_ROUTE}`
export const LEATHERWOOD_API_ENDPOINT = `${FLOWCODE_ROOT}${LEATHERWOOD_API_PROXY_ROUTE}`
export const SUBSCRIPTION_SERVICE_ENDPOINT = `${FLOWCODE_ROOT}${SUBSCRIPTION_SERVICE_PROXY_ROUTE}`
export const COMMERCE_WORKER_ENDPOINT = `${FLOWCODE_ROOT}${COMMERCE_WORKER_PROXY_ROUTE}`
export const VCARD_API_ENDPOINT = `${FLOWCODE_ROOT}${VCARD_API_PROXY_ROUTE}`
export const NOTIFICATIONS_API_ENDPOINT = `${FLOWCODE_ROOT}${NOTIFICATIONS_API_PROXY_ROUTE}`
export const AUTHENTICATION_V2_ENDPOINT = `${FLOWCODE_ROOT}${AUTHENTICATION_V2_PROXY_ROUTE}`
export const AUTHENTICATION_V4_ENDPOINT = `${FLOWCODE_ROOT}${AUTHENTICATION_V4_PROXY_ROUTE}`
export const ITHACA_ENDPOINT = `${FLOWCODE_ROOT}${ITHACA_PROXY_ENDPOINT}`
export const LOCG_TEMP_ASSETS_ENDPOINT = '/api/locg-temp-assets' // use relative path to address CORS issue due to www subdomain in prod
export const AUTH_CACHE_ENDPOINT = `${FLOWCODE_ROOT}/api/auth-cache`
export const LLM_ENDPOINT = `${FLOWCODE_ROOT}${LLM_PROXY_ROUTE}`

const GET_CURRENT_ACCESS_TOKEN_ENDPOINT_PATH = `/api/auth/get-current-access-token-cookie`
export const GET_CURRENT_ACCESS_TOKEN_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${GET_CURRENT_ACCESS_TOKEN_ENDPOINT_PATH}`
export const STG_GET_CURRENT_ACCESS_TOKEN_COOKIE_ENDPOINT = `https://app.stg.flowcode.com${GET_CURRENT_ACCESS_TOKEN_ENDPOINT_PATH}`

const GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_ENDPOINT_PATH = `/api/auth/get-current-impersonation-access-token-cookie`
export const GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_ENDPOINT_PATH}`
export const STG_GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT = `https://app.stg.flowcode.com${GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_ENDPOINT_PATH}`

const SET_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH = `/api/auth/set-access-token-cookie`
const SIGN_UP_AND_CREATE_COOKIE = `/api/auth/signup-and-create-code`
export const SET_ACCESS_TOKEN_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${SET_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`
export const SIGN_UP_AND_CREATE_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${SIGN_UP_AND_CREATE_COOKIE}`
export const STG_SET_ACCESS_TOKEN_COOKIE_ENDPOINT = `https://app.stg.flowcode.com${SET_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`

const SET_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH = `/api/auth/set-impersonation-access-token-cookie`
export const SET_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${SET_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`
export const STG_SET_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT = `https://app.stg.flowcode.com${SET_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`

const CLEAR_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH = `/api/auth/clear-access-token-cookie`
export const CLEAR_ACCESS_TOKEN_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${CLEAR_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`
export const STG_CLEAR_ACCESS_TOKEN_COOKIE_ENDPOINT = `https://app.stg.flowcode.com${CLEAR_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`

const CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH = `/api/auth/clear-impersonation-access-token-cookie`
export const CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT = `${FLOWCODE_ROOT}${CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`
export const STG_CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT = `https://app.stg.flowcode.com${CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT_PATH}`

export const PRELOGIN_ENDPOINT = `${FLOWCODE_ROOT}/api/frontegg/prelogin`
export const RESET_PASSWORD_ENDPOINT = `${FLOWCODE_ROOT}/api/frontegg/reset-password`
export const TRIGGER_RESET_PASSWORD_ENDPOINT = `${FLOWCODE_ROOT}/api/frontegg/trigger-reset-password`
export const GET_FRONTEGG_USER_ENDPOINT = `${FLOWCODE_ROOT}/api/frontegg/get-user`

export const PROXY_ROUTE_CONFIG = {
  api: {
    externalResolver: true,
    bodyParser: false,
    responseLimit: false
  }
}
