import { ColorSelector } from './GeneratorSections/CodeDesign/ColorSelector/ColorSelector'
import { LoggedOutGeneratorSection } from './LoggedOutCodeGenerator.types'
import dynamic from 'next/dynamic'

const FrameAndText = dynamic(
  () =>
    import(
      /* webpackChunkName: "locg-design-sections" */ './GeneratorSections/CodeDesign/FrameAndText/FrameAndText'
    ).then(mod => mod.FrameAndText),
  { ssr: false }
)
const Logo = dynamic(
  () =>
    import(
      /* webpackChunkName: "locg-design-sections" */ './GeneratorSections/CodeDesign/Logo/Logo'
    ).then(mod => mod.Logo),
  { ssr: false }
)
const ShapeSelector = dynamic(
  () =>
    import(
      /* webpackChunkName: "locg-design-sections" */ './GeneratorSections/CodeDesign/ShapeSelector/ShapeSelector'
    ).then(mod => mod.ShapeSelector),
  { ssr: false }
)

// don't make the first section a dynamic import, as it breaks the scrolling behavior after the first transition
export const LOCG_DESIGN_SECTIONS = [
  {
    id: LoggedOutGeneratorSection.Logo,
    Component: Logo
  },
  {
    id: LoggedOutGeneratorSection.ColorSelector,
    Component: ColorSelector
  },
  {
    id: LoggedOutGeneratorSection.ShapeSelector,
    Component: ShapeSelector
  },
  {
    id: LoggedOutGeneratorSection.FrameAndText,
    Component: FrameAndText
  }
]

export const LOCG_DESIGN_SECTIONS_MOBILE = [
  {
    id: LoggedOutGeneratorSection.ColorSelector,
    Component: ColorSelector
  },
  {
    id: LoggedOutGeneratorSection.ShapeSelector,
    Component: ShapeSelector
  },
  {
    id: LoggedOutGeneratorSection.FrameAndText,
    Component: FrameAndText
  },
  {
    id: LoggedOutGeneratorSection.Logo,
    Component: Logo
  }
]

export const LOCG_DESIGN_SECTIONS_SMALL_VARIANT = [
  {
    id: LoggedOutGeneratorSection.ColorSelector,
    Component: ColorSelector
  },
  {
    id: LoggedOutGeneratorSection.Logo,
    Component: Logo
  },
  {
    id: LoggedOutGeneratorSection.FrameAndText,
    Component: FrameAndText
  },
  {
    id: LoggedOutGeneratorSection.ShapeSelector,
    Component: ShapeSelector
  }
]

export const DEFAULT_FLOWCODE_OPTIONS = {
  data: 'https://flowcode.com/p/eiAZkKvIM',
  qrrotate: false,
  logoWidth: 50,
  logoHeight: 50,
  radiusMask: 89,
  containerText: [
    {
      font: 'inter_medium',
      text: 'PRIVACY.FLOWCODE.COM',
      fontSize: 9,
      position: {
        property: 7,
        offsetPercentage: 2
      },
      fontColor: '#000000'
    }
  ],
  containerShape: 1,
  gridModuleColor: '#000000',
  gridModuleShape: 4,
  containerBorderSize: 8.5,
  gridBackgroundColor: '#ffffff',
  containerBorderColor: '#000000',
  errorCorrectionLevel: 2,
  gridModuleRandomSizes: '100',
  dataGridPercentageSize: 70,
  gridModuleColorFillSvg: false,
  positionElementTopLeft: {
    fillSvg: false,
    innerShape: 2,
    outerShape: 2,
    backgroundColor: '#ffffff',
    innerShapeColor: '#000000',
    innerShapeWidth: 45,
    outerShapeColor: '#000000',
    outerShapeWidth: 15,
    innerShapeOutlineSize: 0,
    outerShapeOutlineSize: 0,
    innerShapeOutlineColor: '#000000',
    outerShapeOutlineColor: '#000000'
  },
  containerEmptySpaceSize: 6,
  positionElementTopRight: {
    fillSvg: false,
    innerShape: 2,
    outerShape: 2,
    backgroundColor: '#ffffff',
    innerShapeColor: '#000000',
    innerShapeWidth: 45,
    outerShapeColor: '#000000',
    outerShapeWidth: 15,
    innerShapeOutlineSize: 0,
    outerShapeOutlineSize: 0,
    innerShapeOutlineColor: '#000000',
    outerShapeOutlineColor: '#000000'
  },
  containerBackgroundColor: '#ffffff',
  containerImageUrlOpacity: 1,
  positionElementBottomLeft: {
    text: {
      font: 'inter_black',
      text: 'FLOWCODE',
      fontSize: 12,
      position: {
        property: 1,
        offsetPercentage: 0
      },
      fontColor: '#000000',
      fontOutlineSize: 0,
      fontOutlineColor: '#000000'
    },
    fillSvg: false,
    innerShape: 2,
    outerShape: 5,
    backgroundColor: '#ffffff',
    innerShapeColor: '#000000',
    innerShapeWidth: 45,
    outerShapeColor: '#000000',
    outerShapeWidth: 15,
    innerShapeOutlineSize: 0,
    outerShapeOutlineSize: 0,
    innerShapeOutlineColor: '#000000',
    outerShapeOutlineColor: '#000000'
  },
  containerOuterPatternColor: '#000000',
  containerOuterPatternShape: 4,
  containerImageBackgroundOnly: false,
  containerImageOverlayPrivacy: true,
  containerBackgroundInsetExtend: 0,
  containerOuterPatternRandomSizes: '100',
  containerOuterPatternColorFillSvg: false
}

export const SCROLL_CONTAINER_ID = 'locg-scrollable-container'

export const TRANSITION_DURATION = 500
